import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  projectList,
  projectUpdateAction,
} from "../../redux/actions/projectActions";
import { EditValidation } from "./FormValidation";
import Icon from "@material-ui/core/Icon";
import API_URL from "../../utils/api";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>format_list_bulleted</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
    companies,
    businessUnits,
    suppliers,
  } = props;

  const [status, setStatus] = React.useState("Y");
  const [supplier_id, setSupplierId] = React.useState("");
  const [business_unit_id, setBusinessUnitId] = React.useState("");
  const [order_ref_img, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");
  const [type, setProjectType] = React.useState("");

  React.useEffect(() => {
    setStatus(item.status);
    setSupplierId(item.supplier_id);
    setBusinessUnitId(item.business_unit_name);
    setProjectType(item.type);
    setMenuImagePreview(`${API_URL}/api/upload-images/${item.order_ref_img}`);
    setPhoto(item.order_ref_img);
    console.log("item", item);
  }, [item]);

  const projectUpdate = useSelector((state) => state.projectUpdate);
  const { success, projectUpdates } = projectUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("business_unit_name" in fieldValues)
      temp.business_unit_name = fieldValues.business_unit_name
        ? ""
        : "* Business Unit Name is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const project_name = values.project_name;
  const budget = values.budget;
  const st_date = values.st_date;
  const end_date = values.end_date;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        projectUpdateAction(
          item.id,
          project_name,
          supplier_id,
          business_unit_id,
          type,
          budget,
          st_date,
          end_date,
          status,
          order_ref_img
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(projectList());
      }, 1000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Project updated successfully.",
        type: "success",
      });
    }
  }, [success]);

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Project Modify
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          item={item}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          businessUnits={businessUnits}
          suppliers={suppliers}
          setSupplierId={setSupplierId}
          setBusinessUnitId={setBusinessUnitId}
          setStatus={setStatus}
          status={status}
          setPhoto={setPhoto}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          handleUploadClick={handleUploadClick}
          setProjectType={setProjectType}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
