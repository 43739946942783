import React from "react";
import IBBL from "../../../assets/images/02.jpg";
import EBL from "../../../assets/images/01.jpg";
import PRIME from "../../../assets/images/03.jpg";
import NRBC from "../../../assets/images/05.jpeg";
import moment from "moment";
import API_URL from "../../api";

export const Printable = React.forwardRef((props, ref) => {
  const { pdfData, imageShow } = props;

  //   console.log(pdfData?.cheque_date.split("-")[3]);

  let day;
  let month;
  let year;
  let i = 0;

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          ""
        : "";
    return str
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  function numberWithCommas(x) {
    return x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  console.log(day, month, year);
  return (
    <div ref={ref}>
      {pdfData.length ? (
        pdfData.map((item) => {
          day = item.cheque_date.slice(8, 10);
          month = item.cheque_date.slice(5, 7);
          year = item.cheque_date.slice(0, 4);
          let amount = parseInt(item.amount);
          if (item.print_status === "Y") {
            i++;
            return (
              <div style={{ height: 359.72, width: 925 }}>
                <div
                  id="mainDiv"
                  className="page_break"
                  style={{
                    position: "relative",
                    height: "336px",
                    backgroundImage: imageShow
                      ? `url(${
                          item.bank_id === 1
                            ? EBL
                            : item.bank_id === 2
                            ? IBBL
                            : item.bank_id === 3
                            ? PRIME
                            : item.bank_id === 11
                            ? NRBC
                            : null
                        })`
                      : null,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    width: "750px",
                    top: "23.72px",
                    left: "192px",
                    right: 0,

                    // border: "2px solid red",
                    // backgroundColor: "green",
                  }}
                >
                  {/* <div style={{position: 'relative',margin:'1em',height:'945px',width:'718.11px'}}> */}

                  <p
                    style={{
                      position: "absolute",
                      top: "56px",
                      left:
                        item.bank_id === 1
                          ? "525px"
                          : item.bank_id === 2
                          ? "525px"
                          : "525px",
                      fontSize: 14,
                      letterSpacing: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {day}
                  </p>

                  <p
                    style={{
                      position: "absolute",
                      top: "56px",
                      left:
                        item.bank_id === 1
                          ? "575px"
                          : item.bank_id === 2
                          ? "575px"
                          : "575px",
                      fontSize: 14,
                      letterSpacing: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {month}
                  </p>

                  <p
                    style={{
                      position: "absolute",
                      top: "56px",
                      left:
                        item.bank_id === 1
                          ? "625px"
                          : item.bank_id === 2
                          ? "622px"
                          : "625px",
                      fontSize: 14,
                      letterSpacing: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {year}
                  </p>

                  <p
                    style={{
                      position: "absolute",
                      top: "115px",
                      left: "90px",
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    **{item.supplier_n}**
                  </p>

                  <p
                    style={{
                      position: "absolute",
                      top: "30px",
                      left: "2px",
                      fontSize: 12,
                      fontWeight: "bold",
                      transform: "rotate(-40deg)",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {item.payment_mode === "P" ? "A/C Payee Only" : ""}
                  </p>

                  {/* <p
                  style={{
                    position: "absolute",
                    width: "145px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    top:
                      item.bank_id === 38
                        ? "85px"
                        : item.bank_id === 39
                        ? "105px"
                        : item.bank_id === 41
                        ? "165px"
                        : "87px",
                    left:
                      item.bank_id === 38
                        ? "70px"
                        : item.bank_id === 39
                        ? "75px"
                        : item.bank_id === 41
                        ? "70px"
                        : "80px",
                    fontSize: 8,
                    fontWeight: "bold",
                  }}
                >
                  {item.supplier_n}
                </p> */}

                  {/* <p
                  style={{
                    position: "absolute",
                    top:
                      item.bank_id === 38
                        ? "85px"
                        : item.bank_id === 39
                        ? "115px"
                        : "97px",
                    left:
                      item.bank_id === 38
                        ? "90px"
                        : item.bank_id === 39
                        ? "60px"
                        : "75px",
                    fontSize: 8,
                    fontWeight: "bold",
                  }}
                >
                  {item.supplier_n}
                </p> */}
                  {/* <p
                  style={{
                    position: "absolute",
                    width: "150px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    top:
                      item.bank_id === 38
                        ? "70px"
                        : item.bank_id === 39
                        ? "132px"
                        : "107px",
                    left:
                      item.bank_id === 38
                        ? "100px"
                        : item.bank_id === 39
                        ? "80px"
                        : "80px",
                    fontSize: 8,
                    fontWeight: "bold",
                  }}
                >
                  {item.bank_id === 38 || item.bank_id === 41
                    ? ""
                    : item.narration}
                </p> */}
                  {/* <p
                  style={{
                    position: "absolute",
                    width: "100px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    top:
                      item.bank_id === 38
                        ? "130px"
                        : item.bank_id === 39
                        ? "132px"
                        : "107px",
                    left:
                      item.bank_id === 38
                        ? "118px"
                        : item.bank_id === 39
                        ? "80px"
                        : "80px",
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {item.bank_id === 38 ? item.narration : ""}
                </p> */}

                  {/* <p
                  style={{
                    position: "absolute",
                    top:
                      item.bank_id === 38
                        ? "170px"
                        : item.bank_id === 39
                        ? "195px"
                        : item.bank_id === 41
                        ? "240px"
                        : "195px",
                    left:
                      item.bank_id === 38
                        ? "115px"
                        : item.bank_id === 39
                        ? "120px"
                        : "130px",
                    fontSize: 12,

                    fontWeight: "bold",
                  }}
                >
                  ={numberWithCommas(parseInt(item.amount))}/-
                </p> */}

                  <p
                    style={{
                      position: "absolute",
                      top:
                        item.bank_id === 1
                          ? "170px"
                          : item.bank_id === 2
                          ? "200px"
                          : "195px",
                      left:
                        item.bank_id === 1
                          ? "715px"
                          : item.bank_id === 2
                          ? "720px"
                          : "730px",
                      fontSize: 12,

                      fontWeight: "bold",
                    }}
                  >
                    <img
                      width="40%"
                      src={
                        item.signatory_img
                          ? `${API_URL}/api/upload-images/${item.signatory_img}`
                          : ""
                      }
                    />
                  </p>

                  {/* <p
                  style={{
                    position: "absolute",
                    top:
                      item.bank_id === 38
                        ? "50px"
                        : item.bank_id === 39
                        ? "75px"
                        : item.bank_id === 41
                        ? "140px"
                        : "65px",
                    left:
                      item.bank_id === 38
                        ? "85px"
                        : item.bank_id === 39
                        ? "85px"
                        : "80px",
                    fontSize: 11,
                    fontWeight: "bold",
                  }}
                >
                  {!item.cheque_date
                    ? ""
                    : moment(item.cheque_date).format("DD/MM/YYYY")}
                </p> */}

                  <p
                    style={{
                      position: "absolute",
                      top: "140px",
                      left: item.bank_id === 2 ? "140px" : "130px",
                      fontSize: 13,
                      fontWeight: "bold",
                      maxWidth: "340px",
                      textAlign: "left",
                      lineHeight: "30px",
                    }}
                  >
                    **{inWords(amount)} Taka Only.**
                  </p>
                  <p
                    style={{
                      position: "absolute",
                      top:
                        item.bank_id === 1
                          ? "162px"
                          : item.bank_id === 2
                          ? "162px"
                          : "162px",
                      left:
                        item.bank_id === 1
                          ? "550px"
                          : item.bank_id === 2
                          ? "550px"
                          : "550px",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    ={numberWithCommas(parseInt(item.amount))}/-
                  </p>
                </div>
              </div>
            );
          }
        })
      ) : (
        <div style={{ color: "black" }}></div>
      )}
      {i === 0 ? (
        <h2 style={{ color: "black" }}>No printable item found!</h2>
      ) : (
        ""
      )}
    </div>
  );
});
