import axios from "axios";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_PERMISSION_REQUEST,
  USER_PERMISSION_SUCCESS,
  USER_PERMISSION_FAIL,
} from "../constants/userConstants";
import API_URL from "../../utils/api";

export const login = (user_id, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      `${API_URL}/api/auth/login`,
      { user_id, password },
      config
    );
    // .catch(function (error) {
    //   if (error.response) {
    //     console.log(error.response.data);
    //     console.log(error.response.status);
    //     console.log(error.response.headers);
    //   }
    // });

    console.log("data23", data);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    // localStorage.setItem("userInfo", JSON.stringify(data));
    sessionStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: "error",
    });
  }
};

export const logout = () => (dispatch) => {
  sessionStorage.clear();
  dispatch({ type: USER_LOGOUT });
};

//user list

export const userList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/users`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const userCreateAction =
  (
    business_unit_id,
    first_name,
    phone_no,
    email,
    user_id,
    password,
    user_password,
    user_status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("business_unit_id", business_unit_id);
      formData.append("phone_no", phone_no);
      formData.append("email", email);
      formData.append("user_id", user_id);
      formData.append("password", password);
      formData.append("user_password", user_password);
      formData.append("user_status", user_status);

      console.log("data", formData);

      const config = {
        headers: {},
      };

      const { data } = await axios
        .post(`${API_URL}/api/users`, formData, config)
        .then((res) => {
          console.log(res.data);
        });

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CREATE_FAIL,
        payload:
          error.response && error.response.errors
            ? error.response.errors
            : error.errors,
      });
    }
  };

export const userUpdateAction =
  (
    id,
    business_unit_id,
    first_name,
    phone_no,
    email,
    user_id,
    password,
    user_password,
    user_status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_URL}/api/users/${id}`,
        {
          first_name: first_name,
          phone_no: phone_no,
          email: email,
          user_id: user_id,
          password: password,
          user_status: user_status,
          user_password: user_password,
        },
        config
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const userDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(`${API_URL}/api/users/${id}`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const userPermissionAction = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PERMISSION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/users`, config);

    dispatch({
      type: USER_PERMISSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
