import React, { useEffect, useState, useRef } from "react";
import { Printable } from "./Printable";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import "./styles.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import API_URL from "../../api";

const App = () => {
  const [data, setData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [signature, setSignature] = useState("");
  const [imageShow, setImageShow] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [business_unit_id, setBUnit] = useState(null);

  const location = useLocation();

  const userInfo = sessionStorage.getItem("user");

  useEffect(() => {
    if (userInfo) {
      setBUnit(JSON.parse(userInfo));
    }
  }, [userInfo]);

  console.log("User details", business_unit_id?.business_unit_id);

  let { id } = useParams();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const onBeforeGetContentResolve = React.useRef(Promise.resolve);

  const changePrintStatus = () => {
    console.log("Fired");
  };

  useEffect(() => {
    if (imageShow === false && location.state) {
      fetch(`${API_URL}/api/cheque_entries/print_status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(location.state),
      });
    }
  }, [imageShow, location]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setImageShow(false);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setImageShow(false);
        resolve();
      }, 2000);
    });
  }, [setLoading, setImageShow]);

  useEffect(() => {
    if (business_unit_id) {
      fetch(`${API_URL}/api/cheque_entries/cheque_by_b_unit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          business_unit_id: business_unit_id?.business_unit_id,
        }),
      }).then((response) => {
        response.json().then((result) => {
          // console.log("Checking invoice Data", result);
          let arr = !result ? [] : Object.keys(result).map((k) => result[k]);
          // arr && arr.sort((a, b) => b.id - a.id);
          setData(arr);
        });
      });
    }
  }, [business_unit_id]);

  useEffect(() => {
    if (location.state) {
      var json = location.state.map(function (value, key) {
        return {
          id: value,
        };
      });

      setSignature(json);
    }
  }, [location]);

  const getMatchingList = (data, ids) =>
    data.filter((cat) => ids.some((s) => s.id === cat.id));

  useEffect(() => {
    if (signature.length && data.length) {
      const matchingList = getMatchingList(data, signature);
      setPdfData(matchingList);
    }
  }, [signature, data]);

  console.log("Checking invoice Data", data);
  console.log("Checking pdf Data", pdfData);
  console.log("Checking signature Data", signature);

  console.log("location", location.state);

  React.useEffect(() => {
    if (
      imageShow === false &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, imageShow]);

  const handleAfterPrint = React.useCallback(() => {
    setImageShow(true);
  }, []);

  return (
    <div className="App">
      {pdfData && pdfData.length ? (
        <>
          <Printable
            pdfData={pdfData}
            ref={componentRef}
            id={id}
            imageShow={imageShow}
          />
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  borderRadius: 5,
                  backgroundColor: "#305089",
                  fontSize: "16px",
                  margin: "1em",
                  float: "right",
                  color: "white",
                }}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
            )}
            onBeforeGetContent={handleOnBeforeGetContent}
            onAfterPrint={handleAfterPrint}
            content={() => componentRef.current}
          />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default App;
