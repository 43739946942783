import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { bankList, bankUpdateAction } from "../../redux/actions/bankActions";
import { EditValidation } from "./FormValidation";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>account_balance</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
  } = props;

  const [active_status, setActiveStatus] = React.useState("Y");

  // const [fetchedBuyerLatePolicies, setfetchedBuyerLatePolicies] =
  //   React.useState([]);
  const [bank_accounts, setBAccountList] = React.useState(null);
  const [buyerLatePolicies, setBuyerLatePolicies] = React.useState([]);
  const [fetchedBuyerLatePolicies, setBuyerLatePolicies2] = React.useState([]);

  React.useEffect(() => {
    if (item) {
      fetch(
        `https://cb-api.kashfiknitwears.com/api/cheque_list_details/by_hid`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            id: item?.id,
          }),
        }
      ).then((response) => {
        if (response.status === 200) {
          console.log("ffffffffff", response);
          response.json().then((result) => {
            setBuyerLatePolicies2(result?.data);
          });
        }
      });

      fetch(`https://cb-api.kashfiknitwears.com/api/bank_accounts/by_bank`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: item?.bank_id,
        }),
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            setBAccountList(result?.data);
          });
        }
      });
    }
  }, [item]);

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  const bankUpdate = useSelector((state) => state.bankUpdate);
  const { success, bankUpdates } = bankUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("bank_name" in fieldValues)
      temp.bank_name = fieldValues.bank_name ? "" : "* Bank name is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const bank_name = values.bank_name;
  const description = values.description;

  const submitHandler = (e) => {
    e.preventDefault();
    if (item) {
      var marge = buyerLatePolicies.concat(fetchedBuyerLatePolicies);

      if (marge && marge?.length) {
        const newArr = marge?.map((item2) => {
          return {
            id: item2?.id,
            code_no: item2?.code_no,
            cheque_list_id: item?.id,
            bank_account_id: item2?.bank_account_id,
            page_from: item2?.page_from,
            page_to: item2?.page_to,
            total: item2?.total,
            status: item2?.status,
          };
        });
        fetch(`https://cb-api.kashfiknitwears.com/api/cheque_list_details`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(newArr),
        }).then((response2) => {
          if (response2.status === 200) {
            response2.json().then((result) => {});
          }
        });
      }
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(bankList());
      }, 1000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
    setBuyerLatePolicies2([]);
    setBuyerLatePolicies([]);
  };

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Bank updated successfully.",
        type: "success",
      });
    }
  }, [success]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      fullWidth
      maxWidth={"md"}
      style={{ marginLeft: isSidebarOpened ? 270 : 0 }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Bank Wise Cheque Book Modify
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          item={item}
          handleInputChange={handleInputChange}
          bank_accounts={bank_accounts}
          error={errors}
          value={values}
          helperText={errors}
          setActiveStatus={setActiveStatus}
          active_status={active_status}
          fetchedBuyerLatePolicies={fetchedBuyerLatePolicies}
          setfetchedBuyerLatePolicies={setBuyerLatePolicies2}
          setBuyerLatePolicies2={setBuyerLatePolicies2}
          setBuyerLatePolicies={setBuyerLatePolicies}
          buyerLatePolicies={buyerLatePolicies}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
