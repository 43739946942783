import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { bankList, bankCreateAction } from "../../redux/actions/bankActions";

import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>account_balance</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "600px",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, notify, setNotify, setUserCreatedSuccess } =
    props;

  const [active_status, setActiveStatus] = React.useState("Y");

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  const bankCreate = useSelector((state) => state.bankCreate);
  const { success, bankCreates } = bankCreate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("bank_name" in fieldValues)
      temp.bank_name = fieldValues.bank_name ? "" : "* Bank name is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const bank_name = values.bank_name;
  const description = values.description;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      // dispatch(bankCreateAction(bank_name, description, active_status));
      fetch(`https://cb-api.kashfiknitwears.com/api/banks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          bank_name: bank_name,
          description: description,
          active_status: active_status,
        }),
      }).then((response2) => {
        if (response2.status === 201) {
          response2.json().then((result) => {
            fetch(`https://cb-api.kashfiknitwears.com/api/cheque_list`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                bank_id: result?.id,
              }),
            });
          });
          setNotify({
            isOpen: true,
            message: "Bank created successfully.",
            type: "success",
          });
        }
      });

      setOpenPopup(false);
      setTimeout(() => {
        dispatch(bankList());
      }, 2000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  // useEffect(() => {
  //   if (success === true) {
  //     setUserCreatedSuccess(true);
  //     setNotify({
  //       isOpen: true,
  //       message: "Bank created successfully.",
  //       type: "success",
  //     });
  //   }
  // }, [success]);

  console.log("bankCreates", success, bankCreates);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
      style={{ marginLeft: isSidebarOpened ? 270 : 0 }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Bank Create
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          setActiveStatus={setActiveStatus}
          active_status={active_status}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
