import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    handleInputChange,
    error,
    helperText,
    setActiveStatus,
    active_status,
  } = props;

  const handleStatusChange = (event) => {
    setActiveStatus(event.target.value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="supplier_name"
              variant="outlined"
              required
              fullWidth
              id="supplier_name"
              label="Supplier Name"
              autoFocus
              size="small"
              defaultValue={item ? item.supplier_name : null}
              error={error.supplier_name}
              helperText={helperText.supplier_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="account_name"
              variant="outlined"
              fullWidth
              id="account_name"
              label="Account Name"
              autoFocus
              size="small"
              defaultValue={item ? item.account_name : null}
              error={error.account_name}
              helperText={helperText.account_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="bank_name"
              variant="outlined"
              fullWidth
              id="bank_name"
              label="Bank Name"
              autoFocus
              size="small"
              defaultValue={item ? item.bank_name : null}
              error={error.bank_name}
              helperText={helperText.bank_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="credit_limit"
              variant="outlined"
              fullWidth
              id="credit_limit"
              label="Credit Limit"
              autoFocus
              size="small"
              defaultValue={item ? item.credit_limit : null}
              error={error.credit_limit}
              helperText={helperText.credit_limit}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              fullWidth
              id="phone_no"
              label="Phone No."
              autoFocus
              size="small"
              defaultValue={item ? item.phone_no : null}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email_address"
              variant="outlined"
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="website"
              variant="outlined"
              fullWidth
              id="website"
              label="Website"
              autoFocus
              size="small"
              defaultValue={item ? item.website : null}
              error={error.website}
              helperText={helperText.website}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              id="address"
              label="Address"
              autoFocus
              size="small"
              defaultValue={item ? item.address : null}
              error={error.address}
              helperText={helperText.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="country"
              variant="outlined"
              fullWidth
              id="country"
              label="Country"
              autoFocus
              size="small"
              defaultValue={item ? item.country : null}
              error={error.country}
              helperText={helperText.country}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="contact_person"
              variant="outlined"
              fullWidth
              id="contact_person"
              label="Contact Person"
              autoFocus
              size="small"
              defaultValue={item ? item.contact_person : null}
              error={error.contact_person}
              helperText={helperText.contact_person}
              onChange={handleInputChange}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ marginLeft: 10 }}>
                Active Status
              </FormLabel>
              <RadioGroup
                aria-label="active_status"
                name="active_status"
                defaultValue={item ? item.active_status : active_status}
                onChange={handleStatusChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Inactive</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
