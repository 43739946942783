import {
  BANK_ACCOUNTS_LIST_REQUEST,
  BANK_ACCOUNTS_LIST_SUCCESS,
  BANK_ACCOUNTS_LIST_FAIL,
  BANK_ACCOUNTS_CREATE_REQUEST,
  BANK_ACCOUNTS_CREATE_SUCCESS,
  BANK_ACCOUNTS_CREATE_FAIL,
  BANK_ACCOUNTS_UPDATE_REQUEST,
  BANK_ACCOUNTS_UPDATE_SUCCESS,
  BANK_ACCOUNTS_UPDATE_FAIL,
  BANK_ACCOUNTS_DELETE_REQUEST,
  BANK_ACCOUNTS_DELETE_SUCCESS,
  BANK_ACCOUNTS_DELETE_FAIL,
} from "../constants/bankAccountConstants";

export const bankAccountListReducer = (
  state = { bankAccounts: [] },
  action
) => {
  switch (action.type) {
    case BANK_ACCOUNTS_LIST_REQUEST:
      return { loading: true, bankAccounts: [] };
    case BANK_ACCOUNTS_LIST_SUCCESS:
      return { loading: false, bankAccounts: action.payload };
    case BANK_ACCOUNTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankAccountCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_ACCOUNTS_CREATE_REQUEST:
      return { loading: true };
    case BANK_ACCOUNTS_CREATE_SUCCESS:
      return { loading: false, bankAccountCreates: action.payload };
    case BANK_ACCOUNTS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankAccountUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_ACCOUNTS_UPDATE_REQUEST:
      return { loading: true };
    case BANK_ACCOUNTS_UPDATE_SUCCESS:
      return { loading: false, bankAccountUpdates: action.payload };
    case BANK_ACCOUNTS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankAccountDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_ACCOUNTS_DELETE_REQUEST:
      return { loading: true };
    case BANK_ACCOUNTS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BANK_ACCOUNTS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
