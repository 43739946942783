import React from "react";

//mui
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: 80,
    right: theme.spacing(2),
  },
}));

function Notification(props) {
  const classes = useStyle();
  const { notify, setNotify } = props;

  return (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      elevation={6}
      variant="filled"
      autoHideDuration={2000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setNotify({ isOpen: false })}
    >
      <Alert severity={notify.type}>{notify.message}</Alert>
    </Snackbar>
  );
}
export default Notification;
