import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
} from "./components/redux/reducers/userReducers";
import {
  moduleReducerGlobal,
  moduleReducerSidebar,
} from "./components/redux/reducers/moduleReducers";

import { toggleSidebarReducer } from "./components/redux/reducers/globalStateReducers";

import {
  bankListReducer,
  bankCreateReducer,
  bankUpdateReducer,
  bankDeleteReducer,
} from "./components/redux/reducers/bankReducers";

import {
  bankAccountListReducer,
  bankAccountCreateReducer,
  bankAccountUpdateReducer,
  bankAccountDeleteReducer,
} from "./components/redux/reducers/bankAccountReducers";

import {
  projectListReducer,
  projectCreateReducer,
  projectUpdateReducer,
  projectDeleteReducer,
} from "./components/redux/reducers/projectReducers";

import {
  companyListReducer,
  companyCreateReducer,
  companyUpdateReducer,
  companyDeleteReducer,
} from "./components/redux/reducers/companyReducers";

import {
  supplierListReducer,
  supplierCreateReducer,
  supplierUpdateReducer,
  supplierDeleteReducer,
} from "./components/redux/reducers/supplierReducers";

import {
  businessUnitListReducer,
  businessUnitCreateReducer,
  businessUnitUpdateReducer,
  businessUnitDeleteReducer,
} from "./components/redux/reducers/businessUnitReducers";

import {
  chequeListReducer,
  chequeCreateReducer,
  chequeUpdateReducer,
  chequeDeleteReducer,
  chequeByDateReducer,
  chequeClearReducer,
} from "./components/redux/reducers/chequeEntryReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  module: moduleReducerSidebar,
  moduleGlobal: moduleReducerGlobal,
  leftSidebar: toggleSidebarReducer,

  //users
  user: userListReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,

  //banks
  bank: bankListReducer,
  bankCreate: bankCreateReducer,
  bankUpdate: bankUpdateReducer,
  bankDelete: bankDeleteReducer,

  //bank_accounts
  bankAccount: bankAccountListReducer,
  bankAccountCreate: bankAccountCreateReducer,
  bankAccountUpdate: bankAccountUpdateReducer,
  bankAccountDelete: bankAccountDeleteReducer,

  //suppliers
  supplier: supplierListReducer,
  supplierCreate: supplierCreateReducer,
  supplierUpdate: supplierUpdateReducer,
  supplierDelete: supplierDeleteReducer,

  //companies
  company: companyListReducer,
  companyCreate: companyCreateReducer,
  companyUpdate: companyUpdateReducer,
  companyDelete: companyDeleteReducer,

  //projects
  project: projectListReducer,
  projectCreate: projectCreateReducer,
  projectUpdate: projectUpdateReducer,
  projectDelete: projectDeleteReducer,

  //businessUnits
  businessUnit: businessUnitListReducer,
  businessUnitCreate: businessUnitCreateReducer,
  businessUnitUpdate: businessUnitUpdateReducer,
  businessUnitDelete: businessUnitDeleteReducer,

  //cheque_entries
  cheque: chequeListReducer,
  chequeCreate: chequeCreateReducer,
  chequeUpdate: chequeUpdateReducer,
  chequeDelete: chequeDeleteReducer,
  chequeByDate: chequeByDateReducer,
  chequeClear: chequeClearReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
