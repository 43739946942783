import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { bankList } from "../../redux/actions/bankActions";
import Notification from "../../notification";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [userCreatedSuccess, setUserCreatedSuccess] = useState(false);
  const [chequeList, setChequeList] = React.useState([]);

  const bank = useSelector((state) => state.bank);

  const { banks } = bank;

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    dispatch(bankList());
    setUserCreatedSuccess(false);
    fetch(`https://cb-api.kashfiknitwears.com/api/cheque_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          console.log("ressss", result);
          setChequeList(result?.data);
        });
      }
    });
  }, [dispatch]);

  const classes = useStyles();

  console.log("chequeList", chequeList);

  return (
    <div>
      {!banks.data ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>
              format_list_bulleted
            </Icon>
            <h2 style={{ marginLeft: 5 }}>Cheque Book List</h2>
          </div>
          <Table
            banks={banks.data.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            )}
            chequeList={chequeList && chequeList.length ? chequeList : []}
            notify={notify}
            setNotify={setNotify}
            setUserCreatedSuccess={setUserCreatedSuccess}
          />
          {userCreatedSuccess && (
            <Notification notify={notify} setNotify={setNotify} />
          )}
        </div>
      )}
    </div>
  );
};

export default Body;
