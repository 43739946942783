import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    handleInputChange,
    error,
    helperText,
    setActiveStatus,
    active_status,
  } = props;

  const handleStatusChange = (event) => {
    setActiveStatus(event.target.value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="company_name"
              variant="outlined"
              required
              fullWidth
              id="company_name"
              label="Company Name"
              autoFocus
              size="small"
              defaultValue={item ? item.company_name : null}
              error={error.company_name}
              helperText={helperText.company_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="slogan"
              variant="outlined"
              fullWidth
              id="slogan"
              label="Slogan"
              autoFocus
              size="small"
              defaultValue={item ? item.slogan : null}
              error={error.slogan}
              helperText={helperText.slogan}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="street"
              variant="outlined"
              fullWidth
              id="street"
              label="Street"
              autoFocus
              size="small"
              defaultValue={item ? item.street : null}
              error={error.street}
              helperText={helperText.street}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="city"
              variant="outlined"
              fullWidth
              id="city"
              label="City"
              autoFocus
              size="small"
              defaultValue={item ? item.city : null}
              error={error.city}
              helperText={helperText.city}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="zip_code"
              variant="outlined"
              fullWidth
              id="zip_code"
              label="Zip Code"
              autoFocus
              size="small"
              defaultValue={item ? item.zip_code : null}
              error={error.zip_code}
              helperText={helperText.zip_code}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email_address"
              variant="outlined"
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              fullWidth
              id="phone_no"
              label="Phone No"
              autoFocus
              size="small"
              defaultValue={item ? item.phone_no : null}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="vat_registration_no"
              variant="outlined"
              fullWidth
              id="vat_registration_no"
              label="VAT Reg. No."
              autoFocus
              size="small"
              defaultValue={item ? item.vat_registration_no : null}
              error={error.vat_registration_no}
              helperText={helperText.vat_registration_no}
              onChange={handleInputChange}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ marginLeft: 10 }}>
                Active Status
              </FormLabel>
              <RadioGroup
                aria-label="active_status"
                name="active_status"
                defaultValue={item ? item.active_status : active_status}
                onChange={handleStatusChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Inactive</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
