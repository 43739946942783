import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  chequeListByB_Unit,
  chequeUpdateAction,
} from "../../redux/actions/chequeEntryActions";
import { EditValidation } from "./FormValidation";
import Icon from "@material-ui/core/Icon";
import API_URL from "../../utils/api";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>add_card</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
    businessUnits,
    suppliers,
    projects,
    bankAccounts,
    cheques,
    businessUnitId,
  } = props;

  const [status, setStatus] = React.useState("N");
  const [supplier_id, setSupplierId] = React.useState("");
  const [business_unit_id, setBusinessUnitId] = React.useState("");
  const [bank_account_id, setBankAccountId] = React.useState("");
  const [project_id, setProjectId] = React.useState("");
  const [cheque_img, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");
  const [filteredProject, setFilteredProject] = React.useState([]);
  const [filteredSupplier, setFilteredSupplier] = React.useState([]);
  const [payment_type, setPaymentType] = React.useState("I");
  const [print_status, setPrintStatus] = React.useState("Y");
  const [payment_mode, setPaymentMode] = React.useState("P");
  const [getSupplierName, setSupplierName] = React.useState("");

  React.useEffect(() => {
    if (item) {
      setStatus(item.status);
      setSupplierId(item.supplier_id);
      setBusinessUnitId(item.business_unit_id);
      setProjectId(item.project_id);
      setBankAccountId(item?.bank_account_id);
      setPaymentType(item.payment_type);
      setPrintStatus(item.print_status);
      setPaymentMode(item.payment_mode);
      setMenuImagePreview(
        item.cheque_img !== ""
          ? `${API_URL}/api/upload-images/${item.cheque_img}`
          : ""
      );
      setPhoto(item.cheque_img);
    }
  }, [item]);

  const [filterdBankAccList, setFilteredBankAccList] = React.useState([]);

  const chequeUpdate = useSelector((state) => state.chequeUpdate);
  const { success, chequeUpdates } = chequeUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("supplier_n" in fieldValues && payment_mode === "C")
      temp.supplier_n = fieldValues.supplier_n
        ? ""
        : "* This field is required.";

    if ("supplier" in fieldValues && payment_mode === "P")
      temp.supplier = fieldValues.supplier ? "" : "* Supplier is required.";

    if ("B_Unit" in fieldValues)
      temp.B_Unit = fieldValues.B_Unit ? "" : "* This field is required.";

    if ("bank" in fieldValues)
      temp.bank = fieldValues.bank ? "" : "* Bank Account is required.";

    if ("cheque_no" in fieldValues) {
      temp.cheque_no = duplicateChequeNo.length
        ? "* Duplicate Cheque No."
        : fieldValues.cheque_no
        ? ""
        : "* This field is required.";
    }

    if ("amount" in fieldValues)
      temp.amount = fieldValues.amount ? "" : "* This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const exceptItem =
    cheques &&
    cheques.filter(({ id }) => {
      return item?.id !== id;
    });

  const duplicateChequeNo =
    values.cheque_no !== "" &&
    exceptItem &&
    exceptItem
      .filter((p) => p.cheque_no === values?.cheque_no)
      .map((item) => item);

  console.log("duplicateChequeNo", duplicateChequeNo);

  const receiver_name = values.receiver_name;
  const cheque_no = values.cheque_no;
  const amount = values.amount;
  const issue_date = values.issue_date;
  const cheque_date = values.cheque_date;
  const narration = values.narration;
  const supplier_n =
    values.supplier_n === "" ? getSupplierName : values.supplier_n;

  useEffect(() => {
    if (supplier_id !== "") {
      setValues({
        ...values,
        supplier: supplier_id,
      });
    } else {
      setValues({
        ...values,
        supplier: "",
      });
    }
  }, [supplier_id]);

  useEffect(() => {
    if (bank_account_id !== "") {
      setValues({
        ...values,
        bank: bank_account_id,
      });
    } else {
      setValues({
        ...values,
        bank: "",
      });
    }
  }, [bank_account_id]);

  console.log("values", values, bank_account_id);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("kkkkkkkkkkkkk");
      dispatch(
        chequeUpdateAction(
          item.id,
          payment_mode,
          business_unit_id,
          supplier_id,
          supplier_n,
          project_id,
          bank_account_id,
          receiver_name,
          cheque_no,
          amount,
          issue_date,
          cheque_date,
          narration,
          status,
          cheque_img,
          payment_type,
          print_status
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(chequeListByB_Unit(businessUnitId));
      }, 1000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Cheque updated successfully.",
        type: "success",
      });
    }
  }, [success]);

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  useEffect(() => {
    if (business_unit_id && projects.length) {
      const filteredProject = projects
        .filter((item) => {
          return item.business_unit_id === business_unit_id;
        })
        .map((item) => item);
      setFilteredProject(filteredProject);
    }
  }, [business_unit_id, projects]);

  useEffect(() => {
    if (project_id) {
      const filteredProject = projects
        .filter((item) => {
          return item.id === project_id;
        })
        .map((item) => item?.supplier_id);

      const getSuppliers = filteredProject.length
        ? suppliers
            .filter((item) => {
              return item.id === filteredProject[0];
            })
            .map((item) => item)
        : [];

      setFilteredSupplier(getSuppliers);
    }
  }, [project_id, suppliers, projects]);

  React.useEffect(() => {
    if (business_unit_id !== "" && bankAccounts.length) {
      const filterdBankAcc =
        bankAccounts && business_unit_id !== ""
          ? bankAccounts
              .filter((item2) => {
                return item2.business_unit_id === business_unit_id;
              })
              .map((item) => item)
          : [];

      setFilteredBankAccList(filterdBankAcc);
    }
  }, [business_unit_id, bankAccounts]);

  console.log(
    "1223344",
    item.id,
    payment_mode,
    business_unit_id,
    supplier_id,
    supplier_n,
    project_id,
    bank_account_id,
    receiver_name,
    cheque_no,
    amount,
    issue_date,
    cheque_date,
    narration,
    status,
    cheque_img,
    payment_type,
    print_status
  );
  console.log("item", item);
  console.log("supplier_id", supplier_id);
  console.log("project_id", project_id);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Cheque Modify
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          item={item}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          businessUnits={businessUnits}
          setSupplierId={setSupplierId}
          setBusinessUnitId={setBusinessUnitId}
          setStatus={setStatus}
          status={status}
          setPhoto={setPhoto}
          setProjectId={setProjectId}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          handleUploadClick={handleUploadClick}
          projects={projects}
          suppliers={suppliers}
          setBankAccountId={setBankAccountId}
          bankAccounts={filterdBankAccList}
          payment_type={payment_type}
          setPaymentType={setPaymentType}
          print_status={print_status}
          setPrintStatus={setPrintStatus}
          payment_mode={payment_mode}
          setPaymentMode={setPaymentMode}
          setSupplierName={setSupplierName}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
