import axios from "axios";

import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_CREATE_REQUEST,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAIL,
  PROJECT_UPDATE_REQUEST,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAIL,
  PROJECT_DELETE_REQUEST,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAIL,
} from "../constants/projectConstants";

import API_URL from "../../utils/api";

export const projectList = () => async (dispatch) => {
  try {
    dispatch({
      type: PROJECT_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/projects`, config);

    dispatch({
      type: PROJECT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const projectCreateAction =
  (
    project_name,
    supplier_id,
    business_unit_id,
    type,
    budget,
    st_date,
    end_date,
    status,
    order_ref_img
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const formData = new FormData();
      formData.append("project_name", project_name);
      formData.append("supplier_id", supplier_id);
      formData.append("business_unit_id", business_unit_id);
      formData.append("type", type);
      formData.append("budget", budget);
      formData.append("st_date", st_date);
      formData.append("end_date", end_date);
      formData.append("status", status);
      formData.append("order_ref_img", order_ref_img);

      const { data } = await axios.post(
        `${API_URL}/api/projects`,
        formData,
        config
      );

      dispatch({
        type: PROJECT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const projectUpdateAction =
  (
    id,
    project_name,
    supplier_id,
    business_unit_id,
    type,
    budget,
    st_date,
    end_date,
    status,
    order_ref_img
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const formData = new FormData();
      formData.append("project_name", project_name);
      formData.append("supplier_id", supplier_id);
      formData.append("business_unit_id", business_unit_id);
      formData.append("type", type);
      formData.append("budget", budget);
      formData.append("st_date", st_date);
      formData.append("end_date", end_date);
      formData.append("status", status);
      formData.append("order_ref_img", order_ref_img);

      const { data } = await axios.put(
        `${API_URL}/api/projects/${id}`,
        formData,
        config
      );

      dispatch({
        type: PROJECT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const projectDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PROJECT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/projects/${id}`, config);

    dispatch({
      type: PROJECT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
