import React, { useReducer } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  readOnlyTextInput: {
    "& .MuiFilledInput-root": {
      background: "rgb(232, 241, 250)",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    value,
    item,
    handleInputChange,
    error,
    helperText,
    setBusinessUnitId,
    setSupplierId,
    menu_image_preview,
    setMenuImagePreview,
    handleUploadClick,
    setStatus,
    status,
    setPhoto,
    setProjectId,
    businessUnits,
    suppliers,
    projects,
    setBankAccountId,
    bankAccounts,
    payment_type,
    setPaymentType,
    print_status,
    setPrintStatus,
    payment_mode,
    setPaymentMode,
    setSupplierName,
    supplier_id,
  } = props;
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  const handlePrintChange = (event) => {
    setPrintStatus(event.target.value);
  };
  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
  };

  const initialState = {
    supplier: null,
    businessUnit: null,
    project: null,
    bankAccount: null,
  };

  // function reducer(state, action) {
  //   switch (action.type) {
  //     case "SET_SUPPLIER":
  //       return { ...state, supplier: action.payload.options };
  //     case "SET_BUSINESS_UNIT":
  //       return { ...state, businessUnit: action.payload.options };
  //     case "SET_PROJECT":
  //       return { ...state, project: action.payload.options };
  //     case "SET_BANK":
  //       return { ...state, bankAccount: action.payload.options };
  //     case "REMOVE_SUPPLIER":
  //       return {
  //         supplier: state.supplier.filter(
  //           (option) => option.id !== action.payload.id
  //         ),
  //       };
  //     case "REMOVE_BUSINESS_UNIT":
  //       return {
  //         businessUnit: state.businessUnit.filter(
  //           (option) => option.id !== action.payload.id
  //         ),
  //       };
  //     case "REMOVE_PROJECT":
  //       return {
  //         project: state.project.filter(
  //           (option) => option.id !== action.payload.id
  //         ),
  //       };
  //     case "REMOVE_BANK":
  //       return {
  //         bankAccount: state.bankAccount.filter(
  //           (option) => option.id !== action.payload.id
  //         ),
  //       };
  //     case "RESET":
  //       return initialState;
  //     default:
  //       throw new Error();
  //   }
  // }

  // const [state, dispatch] = useReducer(reducer, initialState);

  // const handleSupplierChange = (event, values) => {
  //   dispatch({ type: "SET_SUPPLIER", payload: { options: values } });
  // };
  // const handleBusinessUnitChange = (event, values) => {
  //   dispatch({ type: "SET_BUSINESS_UNIT", payload: { options: values } });
  // };

  // const handleProjectChange = (event, values) => {
  //   dispatch({ type: "SET_PROJECT", payload: { options: values } });
  // };

  // const handleBankChange = (event, values) => {
  //   dispatch({ type: "SET_BANK", payload: { options: values } });
  // };

  // React.useEffect(() => {
  //   if (state.supplier !== null) {
  //     setSupplierId(state?.supplier?.id);
  //     setSupplierName(state?.supplier?.supplier_name);
  //   }
  // }, [state.supplier]);

  // React.useEffect(() => {
  //   if (state.project !== null) {
  //     setProjectId(state?.project?.id);
  //   }
  // }, [state.project]);

  // React.useEffect(() => {
  //   if (state.businessUnit !== null) {
  //     setBusinessUnitId(state?.businessUnit?.id);
  //   }
  // }, [state.businessUnit]);

  // React.useEffect(() => {
  //   if (state.bankAccount !== null) {
  //     setBankAccountId(state?.bankAccount?.id);
  //   }
  // }, [state.bankAccount]);

  // console.log(state);

  const [bUnitValue, setBUnitValue] = React.useState(null);
  const [bUnitInputValue, setBUnitInputValue] = React.useState("");
  const [supplierValue, setSupplierValue] = React.useState(null);
  const [supplierInputValue, setSupplierInputValue] = React.useState("");
  const [projectValue, setProjectValue] = React.useState(null);
  const [projectInputValue, setProjectInputValue] = React.useState("");
  const [bankValue, setBankValue] = React.useState(null);
  const [bankInputValue, setBankInputValue] = React.useState("");

  React.useEffect(() => {
    if (supplierValue && supplierValue.length === 0) {
      setSupplierId(null);
    } else {
      setSupplierId(supplierValue?.id);
      setSupplierName(supplierValue?.supplier_name);
    }
  }, [supplierValue]);

  React.useEffect(() => {
    if (projectValue && projectValue.length === 0) {
      setProjectId(null);
    } else {
      setProjectId(projectValue ? projectValue?.id : "");
    }
  }, [projectValue]);

  React.useEffect(() => {
    if (bUnitValue) {
      setBusinessUnitId(bUnitValue?.id);
    }
  }, [bUnitValue]);

  React.useEffect(() => {
    if (bankValue) {
      setBankAccountId(bankValue?.id);
    }
  }, [bankValue]);

  React.useEffect(() => {
    if (supplierInputValue === "") {
      setSupplierId("");
      setSupplierName("");
    }
  }, [supplierInputValue]);

  React.useEffect(() => {
    if (bUnitInputValue === "") {
      setBusinessUnitId("");
    }
  }, [bUnitInputValue]);

  React.useEffect(() => {
    if (projectInputValue === "") {
      setProjectId("");
    }
  }, [projectInputValue]);

  React.useEffect(() => {
    if (bankInputValue === "") {
      setBankAccountId("");
    }
  }, [bankInputValue]);

  // React.useEffect(() => {
  //   if (businessUnits) {
  //     // setBUnitValue(businessUnits[0]);
  //     setBusinessUnitId(bUnitValue?.id);
  //   }
  // }, [businessUnits]);

  React.useEffect(() => {
    var businessUnitsName =
      businessUnits && item
        ? businessUnits
            .filter((item2) => {
              return item2.id === item.business_unit_id;
            })
            .map((item) => item)
        : [];
    setBUnitValue(businessUnitsName.length ? businessUnitsName[0] : []);
  }, [item, businessUnits]);

  React.useEffect(() => {
    var supplierName =
      suppliers && item
        ? suppliers
            .filter((item2) => {
              return item2.id === item.supplier_id;
            })
            .map((item) => item)
        : [];
    setSupplierValue(supplierName.length ? supplierName[0] : []);
  }, [item, suppliers]);

  React.useEffect(() => {
    var bankName =
      bankAccounts && item
        ? bankAccounts
            .filter((item2) => {
              return item2.id === item.bank_account_id;
            })
            .map((item) => item)
        : [];
    setBankValue(bankName.length ? bankName[0] : []);
  }, [item, bankAccounts]);

  console.log("gggggggg", bankAccounts, item);

  React.useEffect(() => {
    var projectName =
      projects && item
        ? projects
            .filter((item2) => {
              return item2.id === item.project_id;
            })
            .map((item) => item)
        : [];
    setProjectValue(projectName.length ? projectName[0] : []);
  }, [item, projects]);

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 16, marginLeft: 5, marginBottom: 5 }}
              >
                Payment Mode
              </FormLabel>
              <RadioGroup
                aria-label="status"
                InputLabelProps={{ style: { fontSize: 13 } }}
                name="status"
                defaultValue={item ? item.payment_mode : payment_mode}
                onChange={handlePaymentModeChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="P"
                  disabled={item && item.payment_mode === "C" ? true : false}
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>
                      A/C Payee Only
                    </Typography>
                  }
                />
                <FormControlLabel
                  disabled={item && item.payment_mode === "P" ? true : false}
                  value="C"
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 14 }}>Cash</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={bUnitValue}
              fullWidth
              options={businessUnits}
              getOptionLabel={(option) => option.business_unit_name}
              onChange={(event, value) => setBUnitValue(value)}
              inputValue={bUnitInputValue}
              onInputChange={(_, v) => setBUnitInputValue(v)}
              disableClearable={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Business Unit."
                  name="B_Unit"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  error={error.B_Unit}
                  helperText={helperText.B_Unit}
                  onChange={handleInputChange}
                  InputProps={{ ...params.InputProps, readOnly: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {payment_mode === "C" ? (
              <TextField
                name="supplier_n"
                variant="outlined"
                required
                fullWidth
                id="supplier_n"
                label="Supplier Name"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.supplier_n : null}
                error={error.supplier_n}
                helperText={helperText.supplier_n}
                onChange={handleInputChange}
              />
            ) : (
              <>
                <Autocomplete
                  value={supplierValue}
                  fullWidth
                  options={suppliers}
                  getOptionLabel={(option) => option.supplier_name}
                  onChange={(event, value) => setSupplierValue(value)}
                  inputValue={supplierInputValue}
                  onInputChange={(_, v) => setSupplierInputValue(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose A Supplier."
                      name="supplier"
                      variant="outlined"
                      required
                      size="small"
                      fullWidth
                      error={error.supplier}
                      helperText={helperText.supplier}
                      onChange={handleInputChange}
                    />
                  )}
                />
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={projectValue}
              fullWidth
              options={projects}
              getOptionLabel={(option) => option.project_name}
              onChange={(event, value) => setProjectValue(value)}
              inputValue={projectInputValue}
              onInputChange={(_, v) => setProjectInputValue(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Project."
                  name="project"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={error.project}
                  helperText={helperText.project}
                  onChange={handleInputChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              value={bankValue}
              fullWidth
              options={bankAccounts}
              getOptionLabel={(option) => option.account_no}
              onChange={(event, value) => setBankValue(value)}
              inputValue={bankInputValue}
              onInputChange={(_, v) => setBankInputValue(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Bank Account."
                  name="bank"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  error={error.bank}
                  helperText={helperText.bank}
                  onChange={handleInputChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     alert(e.target.value);
              //   }
              // }}
              // inputRef={() => console.log("input")}
              name="receiver_name"
              variant="outlined"
              fullWidth
              id="receiver_name"
              label="Received By"
              autoFocus
              size="small"
              defaultValue={item ? item.receiver_name : null}
              error={error.receiver_name}
              helperText={helperText.receiver_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="issue_date"
              variant="outlined"
              fullWidth
              id="issue_date"
              label="Issue Date"
              type="date"
              autoFocus
              size="small"
              value={value.issue_date}
              error={error.issue_date}
              helperText={helperText.issue_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="cheque_no"
              variant="outlined"
              required
              fullWidth
              id="cheque_no"
              label="Cheque No."
              autoFocus
              size="small"
              defaultValue={item ? item.cheque_no : null}
              error={error.cheque_no}
              helperText={helperText.cheque_no}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="amount"
              variant="outlined"
              required
              fullWidth
              id="amount"
              label="Amount"
              autoFocus
              size="small"
              defaultValue={item ? item.amount : null}
              error={error.amount}
              helperText={helperText.amount}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="cheque_date"
              variant="outlined"
              fullWidth
              id="cheque_date"
              label="Cheque Date"
              type="date"
              autoFocus
              size="small"
              value={value.cheque_date}
              error={error.cheque_date}
              helperText={helperText.cheque_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 16, marginLeft: 5, marginBottom: 5 }}
              >
                Status
              </FormLabel>
              <RadioGroup
                aria-label="status"
                InputLabelProps={{ style: { fontSize: 13 } }}
                name="status"
                defaultValue={item ? item.status : status}
                onChange={handleStatusChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Cleared</Typography>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Uncleared</Typography>
                  }
                />
                <FormControlLabel
                  value="C"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Cancelled</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="narration"
              variant="outlined"
              fullWidth
              id="narration"
              label="Cheque Book No."
              autoFocus
              size="small"
              defaultValue={item ? item.narration : null}
              error={error.narration}
              helperText={helperText.narration}
              onChange={handleInputChange}
            />
          </Grid>
          {status && status === "C" && (
            <Grid item xs={12}>
              <TextField
                name="narration"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                id="narration"
                label="Replaced by Remarks"
                autoFocus
                size="small"
                defaultValue={item ? item.narration : null}
                onChange={handleInputChange}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 16, marginLeft: 5, marginBottom: 5 }}
              >
                Print Status
              </FormLabel>
              <RadioGroup
                aria-label="status"
                InputLabelProps={{ style: { fontSize: 13 } }}
                name="status"
                defaultValue={item ? item.print_status : print_status}
                onChange={handlePrintChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Printable</Typography>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Printed</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 16, marginLeft: 5, marginBottom: 5 }}
              >
                Payment Type
              </FormLabel>
              <RadioGroup
                aria-label="status"
                InputLabelProps={{ style: { fontSize: 13 } }}
                name="status"
                defaultValue={item ? item.payment_type : payment_type}
                onChange={handlePaymentTypeChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="I"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>
                      Advance Payment
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="P"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>
                      Partial Payment
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="F"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>
                      Final Payment
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container xs={12} sm={7} justify="left" alignItems="center">
            <FormControl component="fieldset">
              <FormLabel
                component="div"
                style={{ fontSize: 16, marginLeft: 15, marginBottom: 20 }}
              >
                Cheque Image
              </FormLabel>
              <div>
                {menu_image_preview !== "" ? (
                  <div style={{ marginLeft: 10 }}>
                    <img width="100%" src={menu_image_preview} />

                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        setPhoto("");
                        setMenuImagePreview("");
                      }}
                    >
                      <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(event) => handleUploadClick(event)}
                    />
                    <label htmlFor="contained-button-file">
                      <Fab
                        component="span"
                        size="large"
                        style={{ height: 100, width: 100 }}
                      >
                        <AddPhotoAlternateIcon style={{ fontSize: 40 }} />
                      </Fab>
                    </label>
                  </div>
                )}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
