import React, { useReducer } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();
  const [getBUnitValue, setBUnitValue] = React.useState([]);
  const [getSupplierValue, setSupplierValue] = React.useState([]);

  const {
    value,
    item,
    handleInputChange,
    error,
    helperText,
    setBusinessUnitId,
    setSupplierId,
    businessUnits,
    suppliers,
    menu_image_preview,
    setMenuImagePreview,
    handleUploadClick,
    setStatus,
    status,
    setPhoto,
    setProjectType,
  } = props;

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const initialState = {
    selectedOptions: null,
    selectedOptions2: null,
    selectedOptions3: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS1":
        return { ...state, selectedOptions: action.payload.options };
      case "SET_SELECTED_OPTIONS2":
        return { ...state, selectedOptions2: action.payload.options };

      case "SET_SELECTED_OPTIONS3":
        return { ...state, selectedOptions3: action.payload.options };
      case "REMOVE_OPTION1":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "REMOVE_OPTION2":
        return {
          selectedOptions2: state.selectedOptions2.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "REMOVE_OPTION2":
        return {
          selectedOptions3: state.selectedOptions3.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "RESET":
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange2 = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS1", payload: { options: values } });
  };
  const handleChange3 = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS2", payload: { options: values } });
  };
  const handleChange4 = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS3", payload: { options: values } });
  };

  React.useEffect(() => {
    if (getSupplierValue !== null) {
      setSupplierId(getSupplierValue?.id);
    }
  }, [getSupplierValue]);

  React.useEffect(() => {
    if (getBUnitValue) {
      setBusinessUnitId(getBUnitValue?.id);
    }
  }, [getBUnitValue]);

  React.useEffect(() => {
    if (state.selectedOptions3 !== null) {
      setProjectType(state.selectedOptions3?.id);
    }
  }, [state.selectedOptions3]);

  console.log(state);

  const projectTypes = [
    {
      id: "Purchase",
      value: "Purchase",
    },
    {
      id: "Service",
      value: "Service",
    },
    {
      id: "Others",
      value: "Others",
    },
  ];

  React.useEffect(() => {
    var BUnit =
      businessUnits && item
        ? businessUnits
            .filter((item2) => {
              return item2.id === item.business_unit_id;
            })
            .map((item) => item)
        : [];

    setBUnitValue(BUnit[0]);
  }, [item, businessUnits]);

  React.useEffect(() => {
    var Supplier =
      suppliers && item
        ? suppliers
            .filter((item2) => {
              return item2.id === item.supplier_id;
            })
            .map((item) => item)
        : [];

    setSupplierValue(Supplier[0]);
  }, [item, suppliers]);

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            {item ? (
              <TextField
                name="amt"
                variant="outlined"
                fullWidth
                id="amt"
                label="Business Unit Name"
                autoFocus
                size="small"
                className={classes.textField}
                value={item.business_unit_name}
                error={error.amt}
                helperText={helperText.amt}
                onChange={handleInputChange}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                id="country-select-demo"
                fullWidth
                options={businessUnits}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.business_unit_name}
                renderOption={(option) => (
                  <React.Fragment>{option.business_unit_name}</React.Fragment>
                )}
                onChange={handleChange3}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose A Business Unit."
                    variant="outlined"
                    required
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid> */}
          <Grid item xs={12}>
            <Autocomplete
              value={getBUnitValue}
              fullWidth
              options={businessUnits}
              getOptionLabel={(option) => option.business_unit_name}
              onChange={(event, value) => setBUnitValue(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Business Unit."
                  name="b_unit"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  error={error.b_unit}
                  helperText={helperText.b_unit}
                  onChange={handleInputChange}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="project_name"
              variant="outlined"
              required
              fullWidth
              id="project_name"
              label="Project Name"
              autoFocus
              size="small"
              defaultValue={item ? item.project_name : null}
              error={error.project_name}
              helperText={helperText.project_name}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12}>
            {item ? (
              <TextField
                name="amt"
                variant="outlined"
                fullWidth
                id="amt"
                label="Supplier Name"
                autoFocus
                size="small"
                className={classes.textField}
                value={item.supplier_name}
                error={error.amt}
                helperText={helperText.amt}
                onChange={handleInputChange}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                id="country-select-demo"
                fullWidth
                options={suppliers}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.supplier_name}
                renderOption={(option) => (
                  <React.Fragment>{option.supplier_name}</React.Fragment>
                )}
                onChange={handleChange2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose A Supplier."
                    variant="outlined"
                    required
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid> */}
          <Grid item xs={12}>
            <Autocomplete
              value={getSupplierValue}
              fullWidth
              options={suppliers}
              getOptionLabel={(option) => option.supplier_name}
              onChange={(event, value) => setSupplierValue(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Supplier."
                  name="supplier"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  error={error.supplier}
                  helperText={helperText.supplier}
                  onChange={handleInputChange}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {item ? (
              <TextField
                name="amt"
                variant="outlined"
                fullWidth
                required
                id="amt"
                label="Project Type"
                autoFocus
                size="small"
                className={classes.textField}
                value={item.type}
                error={error.amt}
                helperText={helperText.amt}
                onChange={handleInputChange}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                id="country-select-demo"
                fullWidth
                options={projectTypes}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.value}
                renderOption={(option) => (
                  <React.Fragment>{option.value}</React.Fragment>
                )}
                onChange={handleChange4}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose A Project Type."
                    variant="outlined"
                    required
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
              name="type"
              variant="outlined"
              fullWidth
              id="type"
              label="Type"
              autoFocus
              size="small"
              defaultValue={item ? item.type : null}
              error={error.type}
              helperText={helperText.type}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              name="budget"
              variant="outlined"
              fullWidth
              id="budget"
              label="Budget"
              autoFocus
              size="small"
              defaultValue={item ? item.budget : null}
              error={error.budget}
              helperText={helperText.budget}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="st_date"
              variant="outlined"
              fullWidth
              id="st_date"
              label="Start Date"
              type="date"
              autoFocus
              size="small"
              value={value.st_date}
              error={error.st_date}
              helperText={helperText.st_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="end_date"
              variant="outlined"
              fullWidth
              id="end_date"
              label="End Date"
              type="date"
              autoFocus
              size="small"
              value={value.end_date}
              error={error.end_date}
              helperText={helperText.end_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                InputLabelProps={{ style: { fontSize: 13 } }}
                name="status"
                defaultValue={item ? item.status : status}
                onChange={handleStatusChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Inactive</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container xs={12} sm={12} justify="left" alignItems="center">
            <FormControl component="fieldset">
              <FormLabel component="div">Order Ref. Image</FormLabel>
              {menu_image_preview !== "" ? (
                <div style={{ marginLeft: 10 }}>
                  <img width="100%" src={menu_image_preview} />

                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setPhoto("");
                      setMenuImagePreview("");
                    }}
                  >
                    <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                  </IconButton>
                </div>
              ) : (
                <div style={{ marginLeft: 20 }}>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event) => handleUploadClick(event)}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab
                      component="span"
                      size="large"
                      style={{ height: 100, width: 100 }}
                    >
                      <AddPhotoAlternateIcon style={{ fontSize: 30 }} />
                    </Fab>
                  </label>
                </div>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
