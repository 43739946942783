export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_FAIL = "COMPANY_LIST_FAIL";

export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_CREATE_FAIL = "COMPANY_CREATE_FAIL";

export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_UPDATE_FAIL = "COMPANY_UPDATE_FAIL";

export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST";
export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS";
export const COMPANY_DELETE_FAIL = "COMPANY_DELETE_FAIL";

export const COMPANY_PERMISSION_REQUEST = "COMPANY_PERMISSION_REQUEST";
export const COMPANY_PERMISSION_SUCCESS = "COMPANY_PERMISSION_SUCCESS";
export const COMPANY_PERMISSION_FAIL = "COMPANY_PERMISSION_FAIL";
