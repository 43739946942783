import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  businessUnitList,
  businessUnitUpdateAction,
} from "../../redux/actions/businessUnitActions";
import { EditValidation } from "./FormValidation";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>domain_add</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
    companies,
  } = props;

  const [active_status, setActiveStatus] = React.useState("Y");
  const [company_id, setCompanyId] = React.useState("");

  React.useEffect(() => {
    setCompanyId(item.company_id);
  }, [item]);

  const businessUnitUpdate = useSelector((state) => state.businessUnitUpdate);
  const { success, businessUnitUpdates } = businessUnitUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("business_unit_name" in fieldValues)
      temp.business_unit_name = fieldValues.business_unit_name
        ? ""
        : "* Business Unit Name is required.";

    if ("company" in fieldValues)
      temp.company = fieldValues.company ? "" : "* Company is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const business_unit_name = values.business_unit_name;

  useEffect(() => {
    if (company_id !== "") {
      setValues({
        ...values,
        company: company_id,
      });
    } else {
      setValues({
        ...values,
        company: "",
      });
    }
  }, [company_id]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        businessUnitUpdateAction(item.id, business_unit_name, company_id)
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(businessUnitList());
      }, 1000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Business Unit updated successfully.",
        type: "success",
      });
    }
  }, [success]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Business Unit Modify
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          item={item}
          handleInputChange={handleInputChange}
          error={errors}
          value={values}
          helperText={errors}
          setActiveStatus={setActiveStatus}
          active_status={active_status}
          companies={companies}
          setCompanyId={setCompanyId}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
