import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  lighten,
  makeStyles,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import indigo from "@material-ui/core/colors/indigo";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Popup from "./Popup";
import EditPopup from "../chequeEntries/EditPopup";
// import InputForm from "./InputForm";
import DeletePopup from "./DeletePopup";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InfoIcon from "@material-ui/icons/Info";
import TextField from "@material-ui/core/TextField";
import Notification from "./Notification";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";

import { chequeByDateAction } from "../../redux/actions/chequeEntryActions";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#305089",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, searchValues) {
  const stabilizedThis = searchValues.fn(array).map((el, id) => [el, id]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "business_unit_name",
    numeric: false,
    disablePadding: false,
    label: "Business Unit",
  },
  {
    id: "issue_date",
    numeric: false,
    disablePadding: false,
    label: "Issue Date",
  },
  {
    id: "cheque_date",
    numeric: false,
    disablePadding: false,
    label: "Cheque Date",
  },
  {
    id: "cheque_no",
    numeric: false,
    disablePadding: false,
    label: "Cheque No.",
  },
  {
    id: "supplier_name",
    numeric: false,
    disablePadding: false,
    label: "Supplier Name ",
  },
  {
    id: "bank_name",
    numeric: false,
    disablePadding: true,
    label: "Bank Name",
  },
  {
    id: "account_no",
    numeric: false,
    disablePadding: false,
    label: "Account No.",
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Issued By",
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ borderRadius: 10 }}>
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            size="small"
          />
        </StyledTableCell> */}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    searchBox,
    SearchClose,
    SearchOpen,
    setOpenPopup,
    handleChange,
    searchValues,
    setSearchValues,
    handleSearch,
    handleExport,
    printPermission,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{
        border: "1px solid #305089",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <Tooltip title='Search'>
            <IconButton aria-label='Search' onClick={SearchOpen}>
              <SearchIcon />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Download">
            <IconButton aria-label="Download" disabled={!printPermission}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
          <Button
            size="small"
            variant="outlined"
            startIcon={<GetAppIcon />}
            onClick={handleExport}
          >
            Export
          </Button>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = (theme) => ({
  root: {
    width: "100%",
    fontSize: "0.1em",
  },
  table: {
    minWidth: 750,
  },
  tableBody: {},
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const EnhancedTable = (props) => {
  const {
    chequesByDates,
    banks,
    userData,
    suppliers,
    businessUnits,
    projects,
    bankAccounts,
    setUserCreatedSuccess,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchBox, setSearchBox] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [searchValues, setSearchValues] = React.useState({
    fn: (chequesByDates) => {
      return chequesByDates;
    },
  });
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [id, setId] = React.useState("");
  const [bank_id, setBankId] = React.useState(0);
  const [business_unit_id, setBUnitId] = React.useState(0);
  const [supplier_id, setSupplierId] = React.useState(0);

  const SearchOpen = () => {
    setSearchBox(true);
  };

  const d = new Date();
  const dvToday = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  var date = new Date();
  date.setDate(date.getDate() - 10);

  const finalDate = `${date.getFullYear()}-${
    date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;

  console.log("finalDate", finalDate);
  console.log("dvToday", dvToday);

  const [formData, setFormData] = React.useState({
    from: finalDate,
    to: dvToday,
  });

  const handleExport = () => {
    var filteredObject = chequesByDates.length
      ? chequesByDates
          .filter((item) => {
            return delete item["id"];
          })
          .map((item) => item)
      : [];

    console.log(filteredObject);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(filteredObject);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "ChequeRegister.xlsx");
  };

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (chequesByDates) => {
        if (target.value == "") return chequesByDates;
        else
          return chequesByDates.filter(
            (x) =>
              (x.bank_name
                ? x.bank_name.toLowerCase().includes(target.value)
                : null) ||
              (x.cheque_no
                ? x.cheque_no.toLowerCase().includes(target.value)
                : null) ||
              (x.account_no
                ? x.account_no.toLowerCase().includes(target.value)
                : null) ||
              (x.supplier_name
                ? x.supplier_name.toLowerCase().includes(target.value)
                : null) ||
              (x.business_unit_name
                ? x.business_unit_name.toLowerCase().includes(target.value)
                : null) ||
              (x.amount ? x.amount.toLowerCase().includes(target.value) : null)
          );
      },
    });
  };

  const SearchClose = (value) => {
    setSearchBox(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    console.log("checking property value for sort", property);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = chequesByDates.map((n) => n.id);
      setSelected(newSelecteds);
      setSearchBox(false);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSearchBox(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      chequesByDates ? chequesByDates.length : null - page * rowsPerPage
    );

  const initialState = {
    bank: null,
    business_unit_id: null,
    supplier_id: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_BANK":
        return { ...state, bank: action.payload.options };

      case "SET_B_Unit":
        return { ...state, business_unit_id: action.payload.options };

      case "SET_SUPPLIERS":
        return { ...state, supplier_id: action.payload.options };

      case "REMOVE_BANK":
        return {
          bank: state.bank.filter((option) => option.id !== action.payload.id),
        };

      case "REMOVE_B_Unit":
        return {
          bank: state.business_unit_id.filter(
            (option) => option.id !== action.payload.id
          ),
        };

      case "REMOVE_SUPPLIERS":
        return {
          bank: state.supplier_id.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "RESET":
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch2] = useReducer(reducer, initialState);

  const handleBankChange = (event, values) => {
    dispatch2({ type: "SET_BANK", payload: { options: values } });
  };

  const handleBUnitChange = (event, values) => {
    dispatch2({ type: "SET_B_Unit", payload: { options: values } });
  };

  const handleSuppliersChange = (event, values) => {
    dispatch2({ type: "SET_SUPPLIERS", payload: { options: values } });
  };

  React.useEffect(() => {
    if (state.bank !== null) {
      setBankId(state?.bank?.id);
    } else {
      setBankId(0);
    }
  }, [state.bank]);

  React.useEffect(() => {
    if (state.business_unit_id !== null) {
      setBUnitId(state?.business_unit_id?.id);
    } else {
      setBUnitId(0);
    }
  }, [state.business_unit_id]);

  React.useEffect(() => {
    if (state.supplier_id !== null) {
      setSupplierId(state?.supplier_id?.id);
    } else {
      setSupplierId(0);
    }
  }, [state.supplier_id]);

  useEffect(() => {
    dispatch(
      chequeByDateAction(
        formData.from,
        formData.to,
        bank_id,
        userData?.user_id,
        business_unit_id,
        supplier_id
      )
    );
  }, [
    formData.from,
    formData.to,
    bank_id,
    userData,
    business_unit_id,
    supplier_id,
  ]);

  console.log("bank_id", bank_id, business_unit_id, supplier_id);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          padding: "5px",
          paddingBottom: 10,
        }}
      >
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              style={{ width: "100%" }}
              id="date"
              label="From"
              type="date"
              value={formData.from}
              className={classes.textField}
              onChange={(e) => {
                setFormData({ ...formData, from: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              style={{ width: "100%" }}
              id="date"
              label="To"
              type="date"
              value={formData.to}
              className={classes.textField}
              onChange={(e) => {
                setFormData({ ...formData, to: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={4}>
            {/* <TextField
              variant="outlined"
              fullWidth
              size="small"
              style={{ width: "100%" }}
              id="date"
              label="To"
              type="date"
              value={formData.to}
              className={classes.textField}
              onChange={(e) => {
                setFormData({ ...formData, to: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              id="country-select-demo"
              fullWidth
              options={businessUnits}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.business_unit_name}
              renderOption={(option) => (
                <React.Fragment>{option.business_unit_name}</React.Fragment>
              )}
              onChange={handleBUnitChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Business Unit"
                  variant="outlined"
                  size="small"
                  // error
                  // helperText="Incorrect entry."
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              id="country-select-demo"
              fullWidth
              options={banks}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.bank_name}
              renderOption={(option) => (
                <React.Fragment>{option.bank_name}</React.Fragment>
              )}
              onChange={handleBankChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Bank."
                  variant="outlined"
                  size="small"
                  // error
                  // helperText="Incorrect entry."
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              id="country-select-demo"
              fullWidth
              options={suppliers}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.supplier_name}
              renderOption={(option) => (
                <React.Fragment>{option.supplier_name}</React.Fragment>
              )}
              onChange={handleSuppliersChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Supplier"
                  variant="outlined"
                  size="small"
                  // error
                  // helperText="Incorrect entry."
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          {/* 
          <Grid item xs={4}>
            <Autocomplete
              id="country-select-demo"
              fullWidth
              options={banks}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.bank_name}
              renderOption={(option) => (
                <React.Fragment>{option.bank_name}</React.Fragment>
              )}
              onChange={handleBankChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Bank."
                  variant="outlined"
                  size="small"
                  // error
                  // helperText="Incorrect entry."
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid> */}
          {/* <Grid item xs={4} md={4} sm={4}>
            <div style={{ marginBottom: 25 }}>
              <div style={{ flexGrow: 1, width: "100%" }}>
                <TextField
                  label="Search"
                  id="outlined-size-small"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </Grid> */}
        </Grid>
      </div>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          SearchOpen={SearchOpen}
          SearchClose={SearchClose}
          searchBox={searchBox}
          setOpenPopup={setOpenPopup}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          handleSearch={handleSearch}
          handleExport={handleExport}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={chequesByDates ? chequesByDates.length : null}
            />
            <TableBody>
              {stableSort(
                chequesByDates,
                getComparator(order, orderBy),
                searchValues
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${id}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell
                        onClick={(event) => handleClick(event, row.id)}
                        padding="checkbox"
                        style={{ width: "10%" }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          size="small"
                        />
                      </TableCell> */}
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "20%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.business_unit_name}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="left"
                        // scope="row"
                        padding="checkbox"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                      >
                        {row.issue_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.cheque_date}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.cheque_no}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "20%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.supplier_name}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.bank_name}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.account_no}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.amount}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        {row.created_by}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          color: row?.status === "C" ? "red" : "black",
                        }}
                        padding="checkbox"
                      >
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => {
                            setOpenEditPopup(true);
                            setItem(row);
                            console.log(row);
                          }}
                          style={{ marginRight: 5 }}
                          disabled={
                            userData?.role === "A" || "B" ? false : true
                          }
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, { label: "All", value: -1 }]}
          component="div"
          count={chequesByDates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Popup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        notify={notify}
        setNotify={setNotify}
        banks={banks}
      ></Popup>
      <EditPopup
        setOpenEditPopup={setOpenEditPopup}
        openEditPopup={openEditPopup}
        item={item}
        notify={notify}
        setNotify={setNotify}
        businessUnits={businessUnits}
        suppliers={suppliers}
        projects={projects}
        bankAccounts={bankAccounts}
        cheques={chequesByDates}
        businessUnitId={business_unit_id}
        userData={userData}
        setUserCreatedSuccess={setUserCreatedSuccess}
      />
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
        notify={notify}
        setNotify={setNotify}
      />
    </div>
  );
};

export default EnhancedTable;
