export const BUSINESS_UNIT_LIST_REQUEST = "BUSINESS_UNIT_LIST_REQUEST";
export const BUSINESS_UNIT_LIST_SUCCESS = "BUSINESS_UNIT_LIST_SUCCESS";
export const BUSINESS_UNIT_LIST_FAIL = "BUSINESS_UNIT_LIST_FAIL";

export const BUSINESS_UNIT_CREATE_REQUEST = "BUSINESS_UNIT_CREATE_REQUEST";
export const BUSINESS_UNIT_CREATE_SUCCESS = "BUSINESS_UNIT_CREATE_SUCCESS";
export const BUSINESS_UNIT_CREATE_FAIL = "BUSINESS_UNIT_CREATE_FAIL";

export const BUSINESS_UNIT_UPDATE_REQUEST = "BUSINESS_UNIT_UPDATE_REQUEST";
export const BUSINESS_UNIT_UPDATE_SUCCESS = "BUSINESS_UNIT_UPDATE_SUCCESS";
export const BUSINESS_UNIT_UPDATE_FAIL = "BUSINESS_UNIT_UPDATE_FAIL";

export const BUSINESS_UNIT_DELETE_REQUEST = "BUSINESS_UNIT_DELETE_REQUEST";
export const BUSINESS_UNIT_DELETE_SUCCESS = "BUSINESS_UNIT_DELETE_SUCCESS";
export const BUSINESS_UNIT_DELETE_FAIL = "BUSINESS_UNIT_DELETE_FAIL";

export const BUSINESS_UNIT_PERMISSION_REQUEST =
  "BUSINESS_UNIT_PERMISSION_REQUEST";
export const BUSINESS_UNIT_PERMISSION_SUCCESS =
  "BUSINESS_UNIT_PERMISSION_SUCCESS";
export const BUSINESS_UNIT_PERMISSION_FAIL = "BUSINESS_UNIT_PERMISSION_FAIL";
