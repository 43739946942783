export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAIL = "SUPPLIER_LIST_FAIL";

export const SUPPLIER_CREATE_REQUEST = "SUPPLIER_CREATE_REQUEST";
export const SUPPLIER_CREATE_SUCCESS = "SUPPLIER_CREATE_SUCCESS";
export const SUPPLIER_CREATE_FAIL = "SUPPLIER_CREATE_FAIL";

export const SUPPLIER_UPDATE_REQUEST = "SUPPLIER_UPDATE_REQUEST";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_FAIL = "SUPPLIER_UPDATE_FAIL";

export const SUPPLIER_DELETE_REQUEST = "SUPPLIER_DELETE_REQUEST";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";
export const SUPPLIER_DELETE_FAIL = "SUPPLIER_DELETE_FAIL";

export const SUPPLIER_PERMISSION_REQUEST = "SUPPLIER_PERMISSION_REQUEST";
export const SUPPLIER_PERMISSION_SUCCESS = "SUPPLIER_PERMISSION_SUCCESS";
export const SUPPLIER_PERMISSION_FAIL = "SUPPLIER_PERMISSION_FAIL";
