import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import AppMenuItem from "./ModuleItems";
import { moduleList } from "../../../redux/actions/moduleActions";

import modulesE from "../../../utils/api/modulesE";

import modulesS from "../../../utils/api/modulesS";

import modules from "../../../utils/api/modules";

const Module = (props) => {
  const classes = useStyles();
  const { userData, isSidebarOpened } = props;
  const [data, setData] = useState("");
  const [value, setValue] = useState({
    subModule: [],
  });

  const tree = (function (modules, root) {
    var o = {};
    modules &&
      modules.forEach(function (a) {
        a.children = o[a.id] && o[a.id].children;
        o[a.id] = a;
        o[a.parent_menu] = o[a.parent_menu] || {};
        o[a.parent_menu].children = o[a.parent_menu].children || [];
        o[a.parent_menu].children.push(a);
      });
    return o[root];
  })(modules, 0);

  const tree2 = (function (modulesE, root) {
    var o = {};
    modulesE &&
      modulesE.forEach(function (a) {
        a.children = o[a.id] && o[a.id].children;
        o[a.id] = a;
        o[a.parent_menu] = o[a.parent_menu] || {};
        o[a.parent_menu].children = o[a.parent_menu].children || [];
        o[a.parent_menu].children.push(a);
      });
    return o[root];
  })(modulesE, 0);

  const tree3 = (function (modulesS, root) {
    var o = {};
    modulesS &&
      modulesS.forEach(function (a) {
        a.children = o[a.id] && o[a.id].children;
        o[a.id] = a;
        o[a.parent_menu] = o[a.parent_menu] || {};
        o[a.parent_menu].children = o[a.parent_menu].children || [];
        o[a.parent_menu].children.push(a);
      });
    return o[root];
  })(modulesS, 0);

  console.log("tree", userData);
  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      <div style={{ marginTop: 10 }}>
        {tree2 && userData?.role === "E"
          ? tree2.children.map((item, index) => (
              <div style={{ marginRight: 10, marginLeft: 9, marginTop: 2 }}>
                <AppMenuItem
                  {...item}
                  key={index}
                  isSidebarOpened={isSidebarOpened}
                />
                <Divider style={{ background: "#393a3b" }} />
              </div>
            ))
          : tree3 && userData?.role === "B"
          ? tree3.children.map((item, index) => (
              <div style={{ marginRight: 10, marginLeft: 9, marginTop: 2 }}>
                <AppMenuItem
                  {...item}
                  key={index}
                  isSidebarOpened={isSidebarOpened}
                />
                <Divider style={{ background: "#393a3b" }} />
              </div>
            ))
          : tree
          ? tree.children.map((item, index) => (
              <div style={{ marginRight: 10, marginLeft: 9, marginTop: 2 }}>
                <AppMenuItem
                  {...item}
                  key={index}
                  isSidebarOpened={isSidebarOpened}
                />
                <Divider style={{ background: "#393a3b" }} />
              </div>
            ))
          : null}
      </div>
    </List>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
      height: 1500,
      backgroundColor: "#18263D",
      color: "white",
    },
  })
);

export default Module;
