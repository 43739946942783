import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  chequeListByB_Unit,
  chequeClearAction,
} from "../../redux/actions/chequeEntryActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClearPopup = (props) => {
  const {
    openClearPopup,
    setOpenClearPopup,
    ids,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
    businessUnitId,
    setSelected,
  } = props;

  const handleClose = () => {
    setOpenClearPopup(false);
  };

  const dispatch = useDispatch();

  const chequeClear = useSelector((state) => state.chequeClear);
  const { success, chequeClears } = chequeClear;

  console.log("businessUnitId77777777777", businessUnitId);

  const [deleteReq, setDeleteReq] = React.useState(false);

  const clearHandler = () => {
    dispatch(chequeClearAction(ids));
    setDeleteReq(true);
    setOpenClearPopup(false);
    setSelected([]);
    setTimeout(() => {
      dispatch(chequeListByB_Unit(businessUnitId));
    }, 2000);
  };

  React.useEffect(() => {
    setUserCreatedSuccess(false);
  }, []);

  React.useEffect(() => {
    if (success === true && deleteReq === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Cheque cleared successfully.",
        type: "success",
      });
    }
  }, [success, deleteReq]);

  return (
    <div>
      <Dialog
        open={openClearPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          {" "}
          <p style={{ fontSize: 14 }}>
            Are you sure you want to clear <strong>{ids.length} items</strong>?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={clearHandler}
            color="secondary"
            variant="contained"
            size="small"
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClearPopup;
