import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { supplierList } from "../../redux/actions/supplierActions";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();

  const supplier = useSelector((state) => state.supplier);

  const { suppliers } = supplier;

  console.log("suppliers", suppliers);

  useEffect(() => {
    dispatch(supplierList());
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div>
      {!suppliers.data ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>local_shipping</Icon>
            <h2 style={{ marginLeft: 5 }}>Suppliers</h2>
          </div>
          <Table
            suppliers={suppliers.data.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Body;
