import React, { useReducer } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    handleInputChange,
    error,
    helperText,
    setCompanyId,
    companies,
  } = props;

  const top5Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
  ];

  const initialState = { selectedOptions: null };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };
      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "RESET":
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, setValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    if (value) {
      setCompanyId(value?.id);
    }
  }, [value]);

  React.useEffect(() => {
    if (inputValue === "") {
      setCompanyId("");
    }
  }, [inputValue]);

  React.useEffect(() => {
    var companyName =
      companies && item
        ? companies
            .filter((item2) => {
              return item2.id === item.company_id;
            })
            .map((item) => item)
        : [];

    setValue(companyName[0]);
  }, [item, companies]);

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="business_unit_name"
              variant="outlined"
              required
              fullWidth
              id="business_unit_name"
              label="Business Unit Name"
              autoFocus
              size="small"
              defaultValue={item ? item.business_unit_name : null}
              error={error.business_unit_name}
              helperText={helperText.business_unit_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={value}
              fullWidth
              options={companies}
              getOptionLabel={(option) => option.company_name}
              onChange={(event, value) => setValue(value)}
              inputValue={inputValue}
              onInputChange={(_, v) => setInputValue(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose A Company."
                  name="company"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  error={error.company}
                  helperText={helperText.company}
                  onChange={handleInputChange}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
