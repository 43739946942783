import React, { useReducer } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  readOnlyTextInput: {
    "& .MuiFilledInput-root": {
      background: "rgb(232, 241, 250)",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    menu_image_preview,
    setMenuImagePreview,
    handleUploadClick,
    setPhoto,
  } = props;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid container xs={12} sm={12} justify="left" alignItems="center">
            <FormControl component="fieldset">
              <FormLabel
                component="div"
                style={{ fontSize: 16, marginLeft: 15, marginBottom: 20 }}
              >
                Cheque Image
              </FormLabel>
              <div>
                {menu_image_preview !== "" ? (
                  <div style={{ marginLeft: 10 }}>
                    <img width="100%" src={menu_image_preview} />

                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        setPhoto("");
                        setMenuImagePreview("");
                      }}
                    >
                      <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(event) => handleUploadClick(event)}
                    />
                    <label htmlFor="contained-button-file">
                      <Fab
                        component="span"
                        size="large"
                        style={{ height: 100, width: 100 }}
                      >
                        <AddPhotoAlternateIcon style={{ fontSize: 40 }} />
                      </Fab>
                    </label>
                  </div>
                )}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
