export const BANK_LIST_REQUEST = "BANK_LIST_REQUEST";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";
export const BANK_LIST_FAIL = "BANK_LIST_FAIL";

export const BANK_CREATE_REQUEST = "BANK_CREATE_REQUEST";
export const BANK_CREATE_SUCCESS = "BANK_CREATE_SUCCESS";
export const BANK_CREATE_FAIL = "BANK_CREATE_FAIL";

export const BANK_UPDATE_REQUEST = "BANK_UPDATE_REQUEST";
export const BANK_UPDATE_SUCCESS = "BANK_UPDATE_SUCCESS";
export const BANK_UPDATE_FAIL = "BANK_UPDATE_FAIL";

export const BANK_DELETE_REQUEST = "BANK_DELETE_REQUEST";
export const BANK_DELETE_SUCCESS = "BANK_DELETE_SUCCESS";
export const BANK_DELETE_FAIL = "BANK_DELETE_FAIL";

export const BANK_PERMISSION_REQUEST = "BANK_PERMISSION_REQUEST";
export const BANK_PERMISSION_SUCCESS = "BANK_PERMISSION_SUCCESS";
export const BANK_PERMISSION_FAIL = "BANK_PERMISSION_FAIL";
