import React from "react";
import BankAsia from "../../../assets/images/02.jpg";
import EBL from "../../../assets/images/03.jpg";
import Prime from "../../../assets/images/04.jpg";
import moment from "moment";
import API_URL from "../../api";

export const Printable = React.forwardRef((props, ref) => {
  const { pdfData, imageShow } = props;

  return (
    <div ref={ref}>
      {pdfData.length ? (
        pdfData.map((item) => {
          return (
            <div
              id="mainDiv"
              className="page_break"
              style={{
                position: "relative",
                maxHeight: "842px",
                width: "595px",
                padding: "5em",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  fontSize: 12,
                }}
              >
                <span style={{ fontWeight: "bold" }}>Business Unit Name: </span>
                {item.business_unit_name}
              </p>
              <p
                style={{
                  fontSize: 12,
                }}
              >
                <span style={{ fontWeight: "bold" }}>Issue Date: </span>
                {item.issue_date}
              </p>
              <p
                style={{
                  fontSize: 12,
                }}
              >
                <span style={{ fontWeight: "bold" }}>Party Name: </span>
                {item.supplier_name}
              </p>
              <p
                style={{
                  fontSize: 12,
                }}
              >
                <span style={{ fontWeight: "bold" }}>Project Name: </span>
                {item.project_name}
              </p>

              <img
                src={
                  item.cheque_img !== ""
                    ? `${API_URL}/api/upload-images/${item.cheque_img}`
                    : ""
                }
                height="172.67px"
                width="472.67px"
              />

              <div style={{ marginTop: 200 }}>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Received by: </span>
                </p>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Name: </span>
                </p>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Phone No: </span>
                </p>
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Date: </span>
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <div style={{ color: "black" }}>No Print item found.</div>
      )}
    </div>
  );
});
