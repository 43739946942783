import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    supplier_name: "",
    address: "",
    account_name: "",
    bank_name: "",
    contact_person: "",
    phone_no: "",
    email_address: "",
    website: "",
    country: "",
    credit_limit: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues({
      supplier_name: "",
      address: "",
      account_name: "",
      bank_name: "",
      contact_person: "",
      phone_no: "",
      email_address: "",
      website: "",
      country: "",
      credit_limit: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    supplier_name: item ? item.supplier_name : "",
    address: item ? item.address : "",
    account_name: item ? item.account_name : "",
    bank_name: item ? item.bank_name : "",
    contact_person: item ? item.contact_person : "",
    phone_no: item ? item.phone_no : "",
    email_address: item ? item.email_address : "",
    website: item ? item.website : "",
    country: item ? item.country : "",
    credit_limit: item ? item.credit_limit : "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      supplier_name: item.supplier_name,
      address: item.address,
      account_name: item.account_name,
      bank_name: item.bank_name,
      contact_person: item.contact_person,
      phone_no: item.phone_no,
      email_address: item.email_address,
      website: item.website,
      country: item.country,
      credit_limit: item.credit_limit,
    });
  }, [item]);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
