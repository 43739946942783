import axios from "axios";

import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAIL,
  SUPPLIER_CREATE_REQUEST,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_CREATE_FAIL,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_FAIL,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAIL,
} from "../constants/supplierConstants";

import API_URL from "../../utils/api";

export const supplierList = () => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/suppliers`, config);

    dispatch({
      type: SUPPLIER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const supplierCreateAction =
  (
    supplier_name,
    bank_name,
    address,
    account_name,
    contact_person,
    phone_no,
    email_address,
    website,
    country,
    credit_limit
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SUPPLIER_CREATE_REQUEST,
      });

      const config = {
        headers: {},
      };

      const { data } = await axios.post(
        `${API_URL}/api/suppliers`,
        {
          supplier_name,
          bank_name,
          address,
          account_name,
          contact_person,
          phone_no,
          email_address,
          website,
          country,
          credit_limit,
        },
        config
      );

      dispatch({
        type: SUPPLIER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUPPLIER_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const supplierUpdateAction =
  (
    id,
    supplier_name,
    bank_name,
    address,
    account_name,
    contact_person,
    phone_no,
    email_address,
    website,
    country,
    credit_limit
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SUPPLIER_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_URL}/api/suppliers/${id}`,
        {
          supplier_name,
          bank_name,
          address,
          account_name,
          contact_person,
          phone_no,
          email_address,
          website,
          country,
          credit_limit,
        },
        config
      );

      dispatch({
        type: SUPPLIER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUPPLIER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const supplierDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SUPPLIER_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/suppliers/${id}`, config);

    dispatch({
      type: SUPPLIER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SUPPLIER_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
