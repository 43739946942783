import axios from "axios";

import {
  BANK_LIST_REQUEST,
  BANK_LIST_SUCCESS,
  BANK_LIST_FAIL,
  BANK_CREATE_REQUEST,
  BANK_CREATE_SUCCESS,
  BANK_CREATE_FAIL,
  BANK_UPDATE_REQUEST,
  BANK_UPDATE_SUCCESS,
  BANK_UPDATE_FAIL,
  BANK_DELETE_REQUEST,
  BANK_DELETE_SUCCESS,
  BANK_DELETE_FAIL,
} from "../constants/bankConstants";

import API_URL from "../../utils/api";

export const bankList = () => async (dispatch) => {
  try {
    dispatch({
      type: BANK_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/banks`, config);

    dispatch({
      type: BANK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BANK_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const bankCreateAction =
  (bank_name, description, active_status) => async (dispatch) => {
    try {
      dispatch({
        type: BANK_CREATE_REQUEST,
      });

      const config = {
        headers: {},
      };

      const { data } = await axios.post(
        `${API_URL}/api/banks`,
        { bank_name, description, active_status },
        config
      );

      dispatch({
        type: BANK_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BANK_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const bankUpdateAction =
  (id, bank_name, description, active_status) => async (dispatch) => {
    try {
      dispatch({
        type: BANK_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_URL}/api/banks/${id}`,
        {
          bank_name,
          description,
          active_status,
        },
        config
      );

      dispatch({
        type: BANK_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BANK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const bankDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BANK_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/banks/${id}`, config);

    dispatch({
      type: BANK_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BANK_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
