import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  lighten,
  makeStyles,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Table, FormGroup } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import indigo from "@material-ui/core/colors/indigo";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PublishIcon from "@material-ui/icons/Publish";
import EditIcon from "@material-ui/icons/Edit";
import Popup from "./Popup";
import EditPopup from "./EditPopup";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import InfoIcon from "@material-ui/icons/Info";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useDispatch, useSelector } from "react-redux";
import DeletePopup from "./ChequeListDelete";

const chequeBookStatus = [
  {
    id: "I",
    status: "Intact",
  },
  {
    id: "R",
    status: "Running",
  },
  {
    id: "N",
    status: "Finished",
  },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: 12,
    fontWeight: 500,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "product_type_id",
    numeric: false,
    disablePadding: false,
    required: false,
    label: "Code No.",
    width: 100,
  },
  {
    id: "product_id",
    numeric: false,
    disablePadding: false,
    required: false,
    label: "Account Name",
    width: 230,
  },

  {
    id: "product_id",
    numeric: false,
    disablePadding: false,
    required: false,
    label: "From",
    width: 100,
  },

  {
    id: "product_id",
    numeric: false,
    disablePadding: false,
    required: false,
    label: "To",
    width: 100,
  },

  {
    id: "product_id",
    numeric: false,
    disablePadding: false,
    required: false,
    label: "Page",
    width: 100,
  },
  {
    id: "product_id",
    numeric: false,
    disablePadding: false,
    required: false,
    label: "Status",
    width: 100,
  },

  { id: 4, numeric: true, disablePadding: false, label: "Actions", width: 70 },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ borderRadius: 10 }}>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.width }}
          >
            {headCell.label}{" "}
            {
              <span style={{ color: "red" }}>
                {headCell.required ? "*" : ""}
              </span>
            }
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  headerMenu: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(-2),
  },
  profileMenu: {
    minWidth: 30,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleSearch,
    setOpenBulkDeletePopup,
    permissions,
    anchorEl,
    handleCloseMenu,
    handleSettingdToggle,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{
        border: "1px solid #00796b",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            style={{ minWidth: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            // disabled={
            //   permissions !== null && permissions?.toString().includes("D")
            //     ? false
            //     : true
            // }
            onClick={() => {
              setOpenBulkDeletePopup(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Export" placement="left">
            <IconButton
              aria-label="Export"
              // disabled={
              //   permissions !== null && permissions?.toString().includes("P")
              //     ? false
              //     : true
              // }
              onClick={handleSettingdToggle}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      )}
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
      >
        <MenuItem style={{ maxHeight: 25 }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" style={{}}>
                Pdf
              </Typography>
            }
          />
        </MenuItem>

        <MenuItem style={{ maxHeight: 25 }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" style={{}}>
                CSV
              </Typography>
            }
          />
        </MenuItem>
      </Menu>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "0.1em",
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
  table: {
    minWidth: "100%",
    "& .MuiTableCell-root": {
      borderLeft: "0.5px solid rgba(224, 224, 224, 1)",
    },
  },
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#D0FA58",
      "& > .MuiTableCell-root": {
        color: "",
      },
    },
  },
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  entryPaper: {
    padding: 20,
  },
  selectRoot: {
    "& .MuiAutocomplete-inputRoot": {
      maxHeight: 32,
      minWidth: 150,
    },
  },

  fieldset: {
    [`& fieldset`]: {
      borderRadius: 5,
    },
  },
  dropdownOption: {
    background: "#F3F3F3",
    maxHeight: 300,
    overflow: "scroll",
    overflowX: "hidden",
    fontSize: 14,
  },

  input: {
    height: 30,
  },
  overrides: {
    // MuiInput: {
    //   root: {
    //     borderRadius: 0,
    //     backgroundColor: "#fff",
    //     border: "1px solid pink",
    //     fontSize: 16,
    //     padding: "10px 12px",
    //     width: "calc(100% - 24px)",
    //   },
    // },
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const EnhancedTable = (props) => {
  const {
    buyerLatePolicies,
    setBuyerLatePolicies,
    fetchedBuyerLatePolicies,
    setfetchedBuyerLatePolicies,
    setBuyerLatePolicies2,
    bank_accounts,
    bank_id,
    item,
    notify,
    setNotify,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

  const [id, setId] = React.useState("");
  const [odItem, setOdItem] = React.useState("");
  console.log("bank_accounts", bank_accounts);

  const handleAddEvent = () => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var item = {
      id: id,
      code_no: null,
      bank_account_id: null,
      page_from: null,
      page_to: null,
      total: null,
      status: "I",
    };

    setBuyerLatePolicies((currentArray) => [...currentArray, item]);
  };

  const handleRowDel = (item) => {
    var index = buyerLatePolicies.indexOf(item);
    buyerLatePolicies.splice(index, 1);
    setBuyerLatePolicies([...buyerLatePolicies]);
  };

  const handleFetchedRowDel = (item) => {
    var index = fetchedBuyerLatePolicies.indexOf(item);
    fetchedBuyerLatePolicies.splice(index, 1);
    setfetchedBuyerLatePolicies([...fetchedBuyerLatePolicies]);
  };

  const moreClasses = {
    input: {
      style: {
        fontSize: 12,
      },
    },
  };

  const MyTextField = ({ params, label }) => (
    <TextField
      {...params}
      variant="outlined"
      size="small"
      InputLabelProps={{ ...params.InputLabelProps, ...moreClasses.label }}
      InputProps={{ ...params.InputProps, ...moreClasses.input }}
    />
  );

  const ref1 = React.useRef();
  const ref2 = React.useRef();

  const handleAddValueTable = (evt, e, ref, val) => {
    var item = {
      id: e ? e.target.id.split("-").shift() : evt.target.id,
      name: ref ? ref.current.getAttribute("name") : evt.target.name,
      value: val ? val?.id : evt.target.value,
    };

    console.log("kkkk", item);

    var id = item.id;
    var value = item.value;

    var newItem = buyerLatePolicies.map((o) => {
      for (var key in o) {
        if (key === item.name && o.id === id) {
          o[key] = value;
        }
      }
      return o;
    });

    setBuyerLatePolicies(newItem);
  };

  const handleModifyValueTable = (evt, e, ref, val) => {
    var item = {
      id: e ? e.target.id.split("-").shift() : evt.target.id,
      name: ref ? ref.current.getAttribute("name") : evt.target.name,
      value: val ? val?.id : evt.target.value,
    };

    var id = item.id;
    var value = item.value;

    var newItem = fetchedBuyerLatePolicies.map((o) => {
      for (var key in o) {
        if (key === item.name && o.id == id) {
          o[key] = value;
        }
      }
      return o;
    });

    // setfetchedBuyerLatePolicies(newItem);
    setBuyerLatePolicies2(newItem);
  };

  return (
    <>
      <div className={classes.root}>
        <div style={{ margin: 10 }} />
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              size="small"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // rowCount={orders ? orders.length : null}
              />
              <TableBody
                style={{
                  borderBottom: "0.5px solid rgba(224, 224, 224, 1)",
                  width: "100%",
                }}
              >
                {fetchedBuyerLatePolicies && fetchedBuyerLatePolicies.length
                  ? fetchedBuyerLatePolicies.map((row) => {
                      var statusDefault =
                        chequeBookStatus && row
                          ? chequeBookStatus
                              .filter((item2) => {
                                return item2.id === row?.status;
                              })
                              .map((item) => item)
                          : [];

                      var bankAccount =
                        bank_accounts && row
                          ? bank_accounts
                              .filter((item2) => {
                                return item2.id === row.bank_account_id;
                              })
                              .map((item) => item.account_name)
                          : [];
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ height: 25 }}
                          className={classes.tableRow}
                        >
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.code_no}
                              fullWidth
                              name="code_no"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleModifyValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontSize: 12,
                              minWidth: 125,
                              padding: "0 5px 0 5px",
                            }}
                            padding="checkbox"
                          >
                            {bankAccount && bankAccount.length
                              ? bankAccount[0]
                              : null}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.page_from}
                              fullWidth
                              name="page_from"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleModifyValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.page_to}
                              fullWidth
                              name="page_to"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleModifyValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.total}
                              fullWidth
                              name="total"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleModifyValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <Autocomplete
                              //   value={
                              //     supplier && supplier.length
                              //       ? supplier[0]
                              //       : supplier
                              //   }
                              value={
                                (statusDefault &&
                                  statusDefault.length &&
                                  statusDefault[0]) ||
                                null
                              }
                              fullWidth
                              ref={ref2}
                              name="status"
                              id={row.id}
                              options={
                                chequeBookStatus && chequeBookStatus.length
                                  ? chequeBookStatus
                                  : []
                              }
                              getOptionLabel={(option) => option?.status}
                              onChange={(e, v, r) => {
                                handleModifyValueTable(e, e, ref2, v);
                              }}
                              PaperComponent={({ children }) => (
                                <Paper className={classes.dropdownOption}>
                                  {children}
                                </Paper>
                              )}
                              className={classes.selectRoot}
                              renderInput={(params) => (
                                <MyTextField params={params} label={""} />
                              )}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            padding="checkbox"
                            style={{
                              minWidth: 70,
                              height: 30,
                            }}
                          >
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  setOpenDeletePopup(true);
                                  setId(row);
                                }}
                                style={{ marginRight: 5 }}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  style={{
                                    fill: "red",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
                {buyerLatePolicies && buyerLatePolicies.length
                  ? buyerLatePolicies.map((row) => {
                      var statusDefault =
                        chequeBookStatus && row
                          ? chequeBookStatus
                              .filter((item2) => {
                                return item2.id === row.status;
                              })
                              .map((item) => item)
                          : [];

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ height: 25 }}
                          //   selected={selectedID === row.id}
                          className={classes.tableRow}
                        >
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.code_no}
                              fullWidth
                              name="code_no"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleAddValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              fontSize: 12,
                              minWidth: 125,
                              padding: "0 5px 0 5px",
                            }}
                            padding="checkbox"
                          >
                            <Autocomplete
                              //   value={
                              //     supplier && supplier.length
                              //       ? supplier[0]
                              //       : supplier
                              //   }
                              fullWidth
                              ref={ref1}
                              name="bank_account_id"
                              id={row.id}
                              options={
                                bank_accounts && bank_accounts.length
                                  ? bank_accounts
                                  : []
                              }
                              getOptionLabel={(option) => option?.account_name}
                              onChange={(e, v, r) => {
                                handleAddValueTable(e, e, ref1, v);
                              }}
                              PaperComponent={({ children }) => (
                                <Paper className={classes.dropdownOption}>
                                  {children}
                                </Paper>
                              )}
                              className={classes.selectRoot}
                              renderInput={(params) => (
                                <MyTextField params={params} label={""} />
                              )}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.page_from}
                              fullWidth
                              name="page_from"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleAddValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.page_to}
                              fullWidth
                              name="page_to"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleAddValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <TextField
                              value={row?.total}
                              fullWidth
                              name="total"
                              id={row.id}
                              variant="outlined"
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleAddValueTable(e);
                              }}
                              inputProps={{
                                autoComplete: "off",
                                style: { fontSize: 12 },
                              }}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontSize: 12, minWidth: 100, padding: 5 }}
                            padding="checkbox"
                          >
                            <Autocomplete
                              //   value={
                              //     supplier && supplier.length
                              //       ? supplier[0]
                              //       : supplier
                              //   }
                              value={
                                (statusDefault &&
                                  statusDefault.length &&
                                  statusDefault[0]) ||
                                null
                              }
                              fullWidth
                              ref={ref2}
                              name="status"
                              id={row.id}
                              options={
                                chequeBookStatus && chequeBookStatus.length
                                  ? chequeBookStatus
                                  : []
                              }
                              getOptionLabel={(option) => option?.status}
                              onChange={(e, v, r) => {
                                handleAddValueTable(e, e, ref2, v);
                              }}
                              PaperComponent={({ children }) => (
                                <Paper className={classes.dropdownOption}>
                                  {children}
                                </Paper>
                              )}
                              className={classes.selectRoot}
                              renderInput={(params) => (
                                <MyTextField params={params} label={""} />
                              )}
                            />
                          </TableCell>

                          <TableCell
                            align="right"
                            padding="checkbox"
                            style={{
                              minWidth: 70,
                              height: 30,
                            }}
                          >
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  handleRowDel(row);
                                }}
                                style={{ marginRight: 5 }}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  style={{
                                    fill: "red",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}

                <TableRow hover tabIndex={-1} style={{ maxHeight: 25 }}>
                  <TableCell>
                    <Chip
                      icon={<AddIcon style={{ fontSize: 18 }} />}
                      label="Add New"
                      onClick={handleAddEvent}
                      disabled={bank_id || item.bank_id ? false : true}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <DeletePopup
          setOpenDeletePopup={setOpenDeletePopup}
          openDeletePopup={openDeletePopup}
          id={id}
          item2={item}
          notify={notify}
          setNotify={setNotify}
          setBuyerLatePolicies2={setBuyerLatePolicies2}
        />
      </div>
    </>
  );
};

export default EnhancedTable;
