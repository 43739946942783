import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    company_name: "",
    slogan: "",
    street: "",
    city: "",
    zip_code: "",
    phone_no: "",
    email_address: "",
    vat_registration_no: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues({
      company_name: "",
      slogan: "",
      street: "",
      city: "",
      zip_code: "",
      phone_no: "",
      email_address: "",
      vat_registration_no: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    company_name: item ? item.company_name : "",
    slogan: item ? item.slogan : "",
    street: item ? item.street : "",
    city: item ? item.city : "",
    zip_code: item ? item.zip_code : "",
    phone_no: item ? item.phone_no : "",
    email_address: item ? item.email_address : "",
    vat_registration_no: item ? item.vat_registration_no : "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      company_name: item.company_name,
      slogan: item.slogan,
      street: item.street,
      city: item.city,
      zip_code: item.zip_code,
      phone_no: item.phone_no,
      email_address: item.email_address,
      vat_registration_no: item.vat_registration_no,
    });
  }, [item]);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
