import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { bankList, bankDeleteAction } from "../../redux/actions/bankActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeletePopup = (props) => {
  const {
    openDeletePopup,
    setOpenDeletePopup,
    id,
    item2,
    notify,
    setNotify,
    setUserCreatedSuccess,
    setBuyerLatePolicies2,
  } = props;

  const handleClose = () => {
    setOpenDeletePopup(false);
  };

  const dispatch = useDispatch();

  const bankDelete = useSelector((state) => state.bankDelete);
  const { success, bankDeletes } = bankDelete;

  const [deleteReq, setDeleteReq] = React.useState(false);

  const deleteHandler = () => {
    fetch(
      `https://cb-api.kashfiknitwears.com/api/cheque_list_details/${id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).then((response2) => {
      if (response2.status === 200) {
      }
    });

    setDeleteReq(true);
    setOpenDeletePopup(false);
    setTimeout(() => {
      fetch(
        `https://cb-api.kashfiknitwears.com/api/cheque_list_details/by_hid`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            id: item2?.id,
          }),
        }
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            setBuyerLatePolicies2(result?.data);
          });
        }
      });
    }, 2000);
  };

  //   React.useEffect(() => {
  //     setUserCreatedSuccess(false);
  //   }, []);

  //   React.useEffect(() => {
  //     if (success === true && deleteReq === true) {
  //       setUserCreatedSuccess(true);
  //       setNotify({
  //         isOpen: true,
  //         message: "Bank deleted successfully.",
  //         type: "success",
  //       });
  //     }
  //   }, [success, deleteReq]);

  return (
    <div>
      <Dialog
        open={openDeletePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          {" "}
          <p style={{ fontSize: 14 }}>Are you sure you want to delete ?</p>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteHandler}
            color="secondary"
            variant="contained"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePopup;
