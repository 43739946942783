import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { userList, userUpdateAction } from "../../redux/actions/userActions";
import { EditValidation } from "./FormValidation";
import Icon from "@material-ui/core/Icon";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>account_tree</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    businessUnits,
  } = props;

  const [user_status, setActiveStatus] = React.useState("Y");
  const [business_unit_id, setBankId] = React.useState("");

  React.useEffect(() => {
    setActiveStatus(item.user_status);
    setBankId(item.business_unit_id);
  }, [item]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name
        ? ""
        : "* This field is required.";

    if ("user_id" in fieldValues)
      temp.user_id = fieldValues.user_id ? "" : "* This field is required.";

    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "* This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const first_name = values.first_name;
  const phone_no = values.phone_no;
  const email = values.email;
  const user_id = values.user_id;
  const password = values.password;
  const user_password = values.password;

  console.log("Password", password);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        userUpdateAction(
          item.id,
          business_unit_id,
          first_name,
          phone_no,
          email,
          user_id,
          password,
          user_password,
          user_status
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(userList());
      }, 1000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        User Modify
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          item={item}
          handleInputChange={handleInputChange}
          error={errors}
          value={values}
          helperText={errors}
          setActiveStatus={setActiveStatus}
          active_status={user_status}
          bank_id={business_unit_id}
          setBankId={setBankId}
          businessUnits={businessUnits}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
