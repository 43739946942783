import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  bankAccountList,
  bankAccountCreateAction,
} from "../../redux/actions/bankAccountActions";

import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>account_tree</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "600px",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, notify, setNotify, banks, businessUnits } =
    props;

  const [active_status, setActiveStatus] = React.useState("Y");
  const [bank_id, setBankId] = React.useState("");
  const [account_type, setType] = React.useState("");
  const [business_unit_id, setBusinessUnit] = React.useState("");

  const [signatory_img, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("account_name" in fieldValues)
      temp.account_name = fieldValues.account_name
        ? ""
        : "* Account name is required.";

    if ("account_no" in fieldValues)
      temp.account_no = fieldValues.account_no
        ? ""
        : "* Account no is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const account_name = values.account_name;
  const account_no = values.account_no;
  const branch_name = values.branch_name;
  const signatory = values.signatory;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        bankAccountCreateAction(
          bank_id,
          business_unit_id,
          account_name,
          account_no,
          branch_name,
          account_type,
          signatory,
          signatory_img,
          active_status
        )
      );
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(bankAccountList());
      }, 2000);
      setNotify({
        isOpen: true,
        message: "Submitted Successfully",
        type: "success",
      });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
      style={{ marginLeft: isSidebarOpened ? 270 : 0 }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Bank Account Create
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          setActiveStatus={setActiveStatus}
          active_status={active_status}
          bank_id={bank_id}
          setBankId={setBankId}
          banks={banks}
          setType={setType}
          businessUnits={businessUnits}
          setBusinessUnit={setBusinessUnit}
          setPhoto={setPhoto}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          handleUploadClick={handleUploadClick}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
