import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { businessUnitList } from "../../redux/actions/businessUnitActions";
import { companyList } from "../../redux/actions/companyActions";

import Notification from "../../notification";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [userCreatedSuccess, setUserCreatedSuccess] = useState(false);

  const businessUnit = useSelector((state) => state.businessUnit);
  const { businessUnits } = businessUnit;

  const company = useSelector((state) => state.company);
  const { companies } = company;

  console.log("businessUnits", businessUnits);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    dispatch(businessUnitList());
    dispatch(companyList());
    setUserCreatedSuccess(false);
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div>
      {!businessUnits.data ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>domain_add</Icon>
            <h2 style={{ marginLeft: 5 }}>Business Units</h2>
          </div>
          <Table
            businessUnits={businessUnits.data.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            )}
            notify={notify}
            setNotify={setNotify}
            setUserCreatedSuccess={setUserCreatedSuccess}
            companies={companies.data}
          />
          {userCreatedSuccess && (
            <Notification notify={notify} setNotify={setNotify} />
          )}
        </div>
      )}
    </div>
  );
};

export default Body;
