import axios from "axios";

import {
  BUSINESS_UNIT_LIST_REQUEST,
  BUSINESS_UNIT_LIST_SUCCESS,
  BUSINESS_UNIT_LIST_FAIL,
  BUSINESS_UNIT_CREATE_REQUEST,
  BUSINESS_UNIT_CREATE_SUCCESS,
  BUSINESS_UNIT_CREATE_FAIL,
  BUSINESS_UNIT_UPDATE_REQUEST,
  BUSINESS_UNIT_UPDATE_SUCCESS,
  BUSINESS_UNIT_UPDATE_FAIL,
  BUSINESS_UNIT_DELETE_REQUEST,
  BUSINESS_UNIT_DELETE_SUCCESS,
  BUSINESS_UNIT_DELETE_FAIL,
} from "../constants/businessUnitConstants";

import API_URL from "../../utils/api";

export const businessUnitList = () => async (dispatch) => {
  try {
    dispatch({
      type: BUSINESS_UNIT_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/business_units`, config);

    dispatch({
      type: BUSINESS_UNIT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BUSINESS_UNIT_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const businessUnitCreateAction =
  (business_unit_name, company_id) => async (dispatch) => {
    try {
      dispatch({
        type: BUSINESS_UNIT_CREATE_REQUEST,
      });

      const config = {
        headers: {},
      };

      const { data } = await axios.post(
        `${API_URL}/api/business_units`,
        { business_unit_name, company_id },
        config
      );

      dispatch({
        type: BUSINESS_UNIT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BUSINESS_UNIT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const businessUnitUpdateAction =
  (id, business_unit_name, company_id) => async (dispatch) => {
    try {
      dispatch({
        type: BUSINESS_UNIT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_URL}/api/business_units/${id}`,
        {
          business_unit_name,
          company_id,
        },
        config
      );

      dispatch({
        type: BUSINESS_UNIT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BUSINESS_UNIT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const businessUnitDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BUSINESS_UNIT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/business_units/${id}`, config);

    dispatch({
      type: BUSINESS_UNIT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BUSINESS_UNIT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
