import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  businessUnitList,
  businessUnitCreateAction,
} from "../../redux/actions/businessUnitActions";

import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>domain_add</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "600px",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    notify,
    setNotify,
    setUserCreatedSuccess,
    companies,
  } = props;

  const [active_status, setActiveStatus] = React.useState("Y");
  const [company_id, setCompanyId] = React.useState("");

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  const businessUnitCreate = useSelector((state) => state.businessUnitCreate);
  const { success, businessUnitCreates } = businessUnitCreate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("business_unit_name" in fieldValues)
      temp.business_unit_name = fieldValues.business_unit_name
        ? ""
        : "* Business Unit Name is required.";

    if ("company" in fieldValues)
      temp.company = fieldValues.company ? "" : "* Company is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const business_unit_name = values.business_unit_name;

  useEffect(() => {
    if (company_id !== "") {
      setValues({
        ...values,
        company: company_id,
      });
    } else {
      setValues({
        ...values,
        company: "",
      });
    }
  }, [company_id]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(businessUnitCreateAction(business_unit_name, company_id));
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(businessUnitList());
      }, 2000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Business Unit created successfully.",
        type: "success",
      });
    }
  }, [success]);

  console.log("values555", values);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
      style={{ marginLeft: isSidebarOpened ? 270 : 0 }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Business Unit Create
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          companies={companies}
          setCompanyId={setCompanyId}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
