import React, { useReducer } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BuyerLatePolicies from "./chequeListDetails";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    handleInputChange,
    error,
    helperText,
    setActiveStatus,
    active_status,
    bank_id,
    setBankId,
    banks,
    buyerLatePolicies,
    setBuyerLatePolicies,
    fetchedBuyerLatePolicies,
    setFetchedBuyerLatePolicies,
    setBuyerLatePolicies2,
    bank_accounts,
  } = props;

  const handleStatusChange = (event) => {
    setActiveStatus(event.target.value);
  };

  const initialState = {
    selectedOptions: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { ...state, selectedOptions: action.payload.options };
      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.id !== action.payload.id
          ),
        };
      case "RESET":
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange2 = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  React.useEffect(() => {
    if (state.selectedOptions !== null) {
      setBankId(state.selectedOptions.id);
    }
  }, [state.selectedOptions]);

  var bankName =
    banks && item
      ? banks
          .filter((item2) => {
            return item2.id === item.bank_id;
          })
          .map((item) => `${item.bank_name}`)
      : "";

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {item ? (
              <TextField
                name="amt"
                variant="outlined"
                fullWidth
                id="amt"
                label="Bank Name"
                autoFocus
                size="small"
                className={classes.textField}
                value={item?.bank_name}
                error={error.amt}
                helperText={helperText.amt}
                onChange={handleInputChange}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                id="country-select-demo"
                fullWidth
                options={banks}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.bank_name}
                renderOption={(option) => (
                  <React.Fragment>{option.bank_name}</React.Fragment>
                )}
                onChange={handleChange2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose A Bank."
                    variant="outlined"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <FormLabel component="legend" style={{ color: "black" }}>
                Check Book Details
              </FormLabel>
              <BuyerLatePolicies
                buyerLatePolicies={buyerLatePolicies}
                setBuyerLatePolicies={setBuyerLatePolicies}
                fetchedBuyerLatePolicies={fetchedBuyerLatePolicies}
                setfetchedBuyerLatePolicies={setFetchedBuyerLatePolicies}
                setBuyerLatePolicies2={setBuyerLatePolicies2}
                bank_accounts={bank_accounts}
                bank_id={bank_id}
                item={item}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
