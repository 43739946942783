import React, { useEffect, useState, useRef } from "react";
import { Printable } from "./Printable";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import "./styles.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import API_URL from "../../api";

const App = () => {
  const [data, setData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [signature, setSignature] = useState("");
  const [imageShow, setImageShow] = useState(true);
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();

  let { id } = useParams();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const onBeforeGetContentResolve = React.useRef(Promise.resolve);

  const changePrintStatus = () => {
    console.log("Fired");
  };

  // useEffect(() => {
  //   if (imageShow === false && location.state) {
  //     fetch(`${API_URL}/api/cheque_entries/print_status`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify(location.state),
  //     });
  //   }
  // }, [imageShow, location]);

  // const handleOnBeforeGetContent = React.useCallback(() => {
  //   console.log("`onBeforeGetContent` called");
  //   setLoading(true);
  //   setImageShow(false);

  //   return new Promise((resolve) => {
  //     onBeforeGetContentResolve.current = resolve;

  //     setTimeout(() => {
  //       setLoading(false);
  //       setImageShow(false);
  //       resolve();
  //     }, 2000);
  //   });
  // }, [setLoading, setImageShow]);

  // useEffect(() => {
  //   fetch(`${API_URL}/api/cheque_entries`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   }).then((response) => {
  //     response.json().then((result) => {
  //       console.log("Checking invoice Data", result);
  //       setData(result.data);
  //     });
  //   });
  // }, []);

  useEffect(() => {
    if (location.state) {
      setPdfData([location.state]);
    }
  }, [location]);

  // const getMatchingList = (data, ids) =>
  //   data.filter((cat) => ids.some((s) => s.id === cat.id));

  // useEffect(() => {
  //   if (signature.length && data.length) {
  //     const matchingList = getMatchingList(data, signature);

  //     setPdfData(matchingList);
  //   }
  // }, [signature, data]);

  // console.log("Checking invoice Data", data);

  // console.log("imageShow", imageShow);

  // React.useEffect(() => {
  //   if (
  //     imageShow === false &&
  //     typeof onBeforeGetContentResolve.current === "function"
  //   ) {
  //     onBeforeGetContentResolve.current();
  //   }
  // }, [onBeforeGetContentResolve.current, imageShow]);

  // const handleAfterPrint = React.useCallback(() => {
  //   setImageShow(true);
  // }, []);

  console.log("pdf", pdfData);

  return (
    <div className="App">
      <Printable
        pdfData={pdfData}
        ref={componentRef}
        // id={id}
        // imageShow={imageShow}
      />
      <ReactToPrint
        trigger={() => (
          <Button
            variant="outlined"
            style={{ margin: "1em", float: "right" }}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        )}
        // onBeforeGetContent={handleOnBeforeGetContent}
        // onAfterPrint={handleAfterPrint}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default App;
