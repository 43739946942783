import {
  BANK_LIST_REQUEST,
  BANK_LIST_SUCCESS,
  BANK_LIST_FAIL,
  BANK_CREATE_REQUEST,
  BANK_CREATE_SUCCESS,
  BANK_CREATE_FAIL,
  BANK_UPDATE_REQUEST,
  BANK_UPDATE_SUCCESS,
  BANK_UPDATE_FAIL,
  BANK_DELETE_REQUEST,
  BANK_DELETE_SUCCESS,
  BANK_DELETE_FAIL,
} from "../constants/bankConstants";

export const bankListReducer = (state = { banks: [] }, action) => {
  switch (action.type) {
    case BANK_LIST_REQUEST:
      return { loading: true, banks: [] };
    case BANK_LIST_SUCCESS:
      return { loading: false, banks: action.payload };
    case BANK_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankCreateReducer = (state = { bankCreates: [] }, action) => {
  switch (action.type) {
    case BANK_CREATE_REQUEST:
      return { loading: true, success: false, bankCreates: [] };
    case BANK_CREATE_SUCCESS:
      return { loading: false, bankCreates: action.payload, success: true };
    case BANK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_UPDATE_REQUEST:
      return { loading: true, success: false };
    case BANK_UPDATE_SUCCESS:
      return { loading: false, bankUpdates: action.payload, success: true };
    case BANK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bankDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BANK_DELETE_REQUEST:
      return { loading: true, success: false };
    case BANK_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BANK_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
