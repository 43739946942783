export const BANK_ACCOUNTS_LIST_REQUEST = "BANK_ACCOUNTS_LIST_REQUEST";
export const BANK_ACCOUNTS_LIST_SUCCESS = "BANK_ACCOUNTS_LIST_SUCCESS";
export const BANK_ACCOUNTS_LIST_FAIL = "BANK_ACCOUNTS_LIST_FAIL";

export const BANK_ACCOUNTS_CREATE_REQUEST = "BANK_ACCOUNTS_CREATE_REQUEST";
export const BANK_ACCOUNTS_CREATE_SUCCESS = "BANK_ACCOUNTS_CREATE_SUCCESS";
export const BANK_ACCOUNTS_CREATE_FAIL = "BANK_ACCOUNTS_CREATE_FAIL";

export const BANK_ACCOUNTS_UPDATE_REQUEST = "BANK_ACCOUNTS_UPDATE_REQUEST";
export const BANK_ACCOUNTS_UPDATE_SUCCESS = "BANK_ACCOUNTS_UPDATE_SUCCESS";
export const BANK_ACCOUNTS_UPDATE_FAIL = "BANK_ACCOUNTS_UPDATE_FAIL";

export const BANK_ACCOUNTS_DELETE_REQUEST = "BANK_ACCOUNTS_DELETE_REQUEST";
export const BANK_ACCOUNTS_DELETE_SUCCESS = "BANK_ACCOUNTS_DELETE_SUCCESS";
export const BANK_ACCOUNTS_DELETE_FAIL = "BANK_ACCOUNTS_DELETE_FAIL";

export const BANK_ACCOUNTS_PERMISSION_REQUEST =
  "BANK_ACCOUNTS_PERMISSION_REQUEST";
export const BANK_ACCOUNTS_PERMISSION_SUCCESS =
  "BANK_ACCOUNTS_PERMISSION_SUCCESS";
export const BANK_ACCOUNTS_PERMISSION_FAIL = "BANK_ACCOUNTS_PERMISSION_FAIL";
