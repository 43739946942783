import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { businessUnitList } from "../../redux/actions/businessUnitActions";
import { projectList } from "../../redux/actions/projectActions";
import { supplierList } from "../../redux/actions/supplierActions";

import Notification from "../../notification";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [userCreatedSuccess, setUserCreatedSuccess] = useState(false);

  const businessUnit = useSelector((state) => state.businessUnit);
  const { businessUnits } = businessUnit;

  const project = useSelector((state) => state.project);
  const { projects } = project;

  const supplier = useSelector((state) => state.supplier);
  const { suppliers } = supplier;

  console.log("businessUnits", businessUnits);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    dispatch(businessUnitList());
    dispatch(projectList());
    dispatch(supplierList());
    setUserCreatedSuccess(false);
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div>
      {!projects.data ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>
              format_list_bulleted
            </Icon>
            <h2 style={{ marginLeft: 5 }}>Projects</h2>
          </div>
          <Table
            projects={projects.data.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            )}
            notify={notify}
            setNotify={setNotify}
            setUserCreatedSuccess={setUserCreatedSuccess}
            businessUnits={businessUnits.data}
            suppliers={suppliers.data}
          />
          {userCreatedSuccess && (
            <Notification notify={notify} setNotify={setNotify} />
          )}
        </div>
      )}
    </div>
  );
};

export default Body;
