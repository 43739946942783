import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../utils/Wrappers";

import { useDispatch, useSelector } from "react-redux";
import { toggleLeftSidebar } from "../redux/actions/globalStateActions";
import { logout } from "../redux/actions/userActions";

import Logo from "../assets/images/logo.png";

export default function Header(props) {
  const { userData } = props;
  var classes = useStyles();
  var theme = useTheme();

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  const user_id = userData.user_id;

  useEffect(() => {
    dispatch(toggleLeftSidebar);
  }, [dispatch, user_id]);

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload();
  };

  // local

  var [profileMenu, setProfileMenu] = useState(null);

  console.log("sidebar", isSidebarOpened);

  const nameIcon = userData ? userData?.first_name.charAt(0).toUpperCase() : "";

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => {
              dispatch(toggleLeftSidebar);
            }}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse
            )}
          >
            {!isPermanent ? (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse
                  ),
                }}
              />
            ) : null}
          </IconButton>
          <Typography variant="h3" weight="medium" style={{}}>
            <Link
              href="/app/me"
              color="inherit"
              style={{ textDecoration: "none" }}
            >
              <img src={Logo} height="45px" />
            </Link>
          </Typography>
          <div className={classes.grow} />
          {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={
              isNotificationsUnread && approvalNotifications.length !== 0
                ? approvalNotifications.length
                : approvalEntryNotifications.length
            }
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> */}
          <Typography variant="h6" weight="medium">
            {userData?.first_name}
          </Typography>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <Avatar>{nameIcon}</Avatar>
          </IconButton>

          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {userData?.first_name}
              </Typography>
              {/* <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
            >
              Administrator
            </Typography> */}
            </div>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Profile
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Tasks
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Messages
            </MenuItem>
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={logoutHandler}
              >
                Sign Out
              </Typography>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}
