import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  chequeListByB_Unit,
  chequeDeleteAction,
} from "../../redux/actions/chequeEntryActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeletePopup = (props) => {
  const {
    openDeletePopup,
    setOpenDeletePopup,
    id,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
    businessUnitId,
  } = props;

  const handleClose = () => {
    setOpenDeletePopup(false);
  };

  const dispatch = useDispatch();

  const chequeDelete = useSelector((state) => state.chequeDelete);
  const { success, chequeDeletes } = chequeDelete;

  console.log("businessUnitId77777777777", businessUnitId);

  const [deleteReq, setDeleteReq] = React.useState(false);

  const deleteHandler = () => {
    dispatch(chequeDeleteAction(id));
    setDeleteReq(true);
    setOpenDeletePopup(false);
    setTimeout(() => {
      dispatch(chequeListByB_Unit(businessUnitId));
    }, 2000);
  };

  React.useEffect(() => {
    setUserCreatedSuccess(false);
  }, []);

  React.useEffect(() => {
    if (success === true && deleteReq === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Cheque deleted successfully.",
        type: "success",
      });
    }
  }, [success, deleteReq]);

  return (
    <div>
      <Dialog
        open={openDeletePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          {" "}
          <p style={{ fontSize: 14 }}>
            Are you sure you want to delete{" "}
            <strong>cheque no. {item.cheque_no}</strong>?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteHandler}
            color="secondary"
            variant="contained"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePopup;
