import axios from "axios";

import {
  BANK_ACCOUNTS_LIST_REQUEST,
  BANK_ACCOUNTS_LIST_SUCCESS,
  BANK_ACCOUNTS_LIST_FAIL,
  BANK_ACCOUNTS_CREATE_REQUEST,
  BANK_ACCOUNTS_CREATE_SUCCESS,
  BANK_ACCOUNTS_CREATE_FAIL,
  BANK_ACCOUNTS_UPDATE_REQUEST,
  BANK_ACCOUNTS_UPDATE_SUCCESS,
  BANK_ACCOUNTS_UPDATE_FAIL,
  BANK_ACCOUNTS_DELETE_REQUEST,
  BANK_ACCOUNTS_DELETE_SUCCESS,
  BANK_ACCOUNTS_DELETE_FAIL,
} from "../constants/bankAccountConstants";

import API_URL from "../../utils/api";

export const bankAccountList = () => async (dispatch) => {
  try {
    dispatch({
      type: BANK_ACCOUNTS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/bank_accounts`, config);

    dispatch({
      type: BANK_ACCOUNTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BANK_ACCOUNTS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const bankAccountCreateAction =
  (
    bank_id,
    business_unit_id,
    account_name,
    account_no,
    branch_name,
    account_type,
    signatory,
    signatory_img,
    active_status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BANK_ACCOUNTS_CREATE_REQUEST,
      });

      const config = {
        headers: {},
      };

      const formData = new FormData();
      formData.append("bank_id", bank_id);
      formData.append("business_unit_id", business_unit_id);
      formData.append("account_name", account_name);
      formData.append("account_no", account_no);
      formData.append("branch_name", branch_name);
      formData.append("account_type", account_type);
      formData.append("signatory", signatory);
      formData.append("signatory_img", signatory_img);
      formData.append("active_status", active_status);

      const { data } = await axios.post(
        `${API_URL}/api/bank_accounts`,
        formData,
        config
      );

      dispatch({
        type: BANK_ACCOUNTS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BANK_ACCOUNTS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const bankAccountUpdateAction =
  (
    id,
    bank_id,
    account_name,
    account_no,
    branch_name,
    account_type,
    signatory,
    signatory_img,
    active_status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BANK_ACCOUNTS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const formData = new FormData();
      formData.append("bank_id", bank_id);
      formData.append("account_name", account_name);
      formData.append("account_no", account_no);
      formData.append("branch_name", branch_name);
      formData.append("account_type", account_type);
      formData.append("signatory", signatory);
      formData.append("signatory_img", signatory_img);
      formData.append("active_status", active_status);

      const { data } = await axios.put(
        `${API_URL}/api/bank_accounts/${id}`,
        formData,
        config
      );

      dispatch({
        type: BANK_ACCOUNTS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BANK_ACCOUNTS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const bankAccountDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BANK_ACCOUNTS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/bank_accounts/${id}`, config);

    dispatch({
      type: BANK_ACCOUNTS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BANK_ACCOUNTS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
