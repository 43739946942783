import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    account_name: "",
    account_no: "",
    branch_name: "",
    account_type: "",
    signatory: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues({
      account_name: "",
      account_no: "",
      branch_name: "",
      account_type: "",
      signatory: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    account_name: item ? item.account_name : "",
    account_no: item ? item.account_no : "",
    branch_name: item ? item.branch_name : "",
    account_type: item ? item.account_type : "",
    signatory: item ? item.signatory : "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      account_name: item.account_name,
      account_no: item.account_no,
      branch_name: item.branch_name,
      account_type: item.account_type,
      signatory: item.signatory,
    });
  }, [item]);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
