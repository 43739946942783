import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import AppMenuItemComponent from "./ModuleChildreenItems";
import Tooltip from "@material-ui/core/Tooltip";

import ModuleChildrenItem from "./ModuleChildrenItem";

const ModuleItems = ({ isSidebarOpened, ...item }) => {
  const {
    menu_caption,
    menu_link,
    parent_menu,
    menu_icon,
    children = [],
  } = item;
  const classes = useStyles();
  const isExpandable = children && children.length > 0;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  function handleClick() {
    setOpen(!open);
    setValue(menu_caption);
  }

  console.log("sidebar", isSidebarOpened);

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      menu_link={menu_link}
      parent_menu={parent_menu}
      onClick={handleClick}
    >
      {/* Display an icon if any */}
      {!!menu_icon && (
        <Tooltip
          title={menu_caption}
          placement="right"
          interactive
          disableHoverListener={isSidebarOpened}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon>{menu_icon}</Icon>
          </ListItemIcon>
        </Tooltip>
      )}
      <ListItemText
        classes={{ primary: classes.listItemText }}
        primary={menu_caption}
        inset={!menu_icon}
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore style={{ fontSize: 18 }} />}
      {isExpandable && open && <IconExpandLess style={{ fontSize: 18 }} />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {children.map((item, index) => (
          <div style={{ marginTop: 5 }}>
            <ModuleChildrenItem
              {...item}
              key={index}
              isSidebarOpened={isSidebarOpened}
            />
          </div>
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      "&.active": {
        background: "#5E81BF",
        "& .MuiListItemIcon-root": {
          color: "#fff",
        },
      },
      borderRadius: 5,
      height: 40,
    },
    menuItemIcon: {
      color: "white",
      marginLeft: -2,
    },
    listItemText: {
      fontSize: 14,
      color: "white",
    },
  })
);

export default ModuleItems;
