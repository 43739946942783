import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { bankAccountList } from "../../redux/actions/bankAccountActions";
import { bankList } from "../../redux/actions/bankActions";
import { businessUnitList } from "../../redux/actions/businessUnitActions";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();

  const bankAccount = useSelector((state) => state.bankAccount);
  const { bankAccounts } = bankAccount;

  const bank = useSelector((state) => state.bank);
  const { banks } = bank;

  const businessUnit = useSelector((state) => state.businessUnit);
  const { businessUnits } = businessUnit;

  useEffect(() => {
    dispatch(bankAccountList());
    dispatch(businessUnitList());
    dispatch(bankList());
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div>
      {!bankAccounts.data ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>account_tree</Icon>
            <h2 style={{ marginLeft: 5 }}>Bank Accounts</h2>
          </div>
          <Table
            bankAccounts={bankAccounts.data.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            )}
            banks={banks.data}
            businessUnits={businessUnits.data}
          />
        </div>
      )}
    </div>
  );
};

export default Body;
