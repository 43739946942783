import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const d = new Date();
  const dv = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  const [values, setValues] = useState({
    project_name: "",
    type: "",
    b_unit: "",
    supplier: "",
    budget: 0,
    st_date: dv,
    end_date: dv,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues({
      project_name: "",
      type: "",
      budget: "",
      st_date: "",
      end_date: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const d = new Date();
  const dv = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  const [values, setValues] = useState({
    project_name: item ? item.project_name : "",
    type: item ? item.type : "",
    budget: item ? item.budget : 0,
    st_date: item ? item.st_date : dv,
    end_date: item ? item.end_date : dv,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      project_name: item.project_name,
      type: item.type,
      budget: item.budget,
      st_date: item.st_date,
      end_date: item.end_date,
    });
  }, [item]);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
