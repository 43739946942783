import axios from "axios";

import {
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_CREATE_FAIL,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
  COMPANY_DELETE_REQUEST,
  COMPANY_DELETE_SUCCESS,
  COMPANY_DELETE_FAIL,
} from "../constants/companyConstants";

import API_URL from "../../utils/api";

export const companyList = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/companies`, config);

    dispatch({
      type: COMPANY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPANY_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const companyCreateAction =
  (
    company_name,
    slogan,
    street,
    city,
    zip_code,
    phone_no,
    email_address,
    vat_registration_no
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: COMPANY_CREATE_REQUEST,
      });

      const config = {
        headers: {},
      };

      const { data } = await axios.post(
        `${API_URL}/api/companies`,
        {
          company_name,
          slogan,
          street,
          city,
          zip_code,
          phone_no,
          email_address,
          vat_registration_no,
        },
        config
      );

      dispatch({
        type: COMPANY_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPANY_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const companyUpdateAction =
  (
    id,
    company_name,
    slogan,
    street,
    city,
    zip_code,
    phone_no,
    email_address,
    vat_registration_no
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: COMPANY_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_URL}/api/companies/${id}`,
        {
          company_name,
          slogan,
          street,
          city,
          zip_code,
          phone_no,
          email_address,
          vat_registration_no,
        },
        config
      );

      dispatch({
        type: COMPANY_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPANY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const companyDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/companies/${id}`, config);

    dispatch({
      type: COMPANY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: COMPANY_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
